import React from 'react'
import Header from '../components/common/header/Header'

const LogPage = () => {
  return (
    <div>
      <Header/>
      <h1>LogPage</h1>
    </div>
  )
}

export default LogPage