import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
//c

const Dropdown = forwardRef(
  (
    {
      onDropdownSelect,
      label,
      list,
      placeholder,
      preFilledvalue,
      required = false,
    },
    ref
  ) => {
    const [dropdown, setValue] = useState(preFilledvalue || "");
    const [inputdropdown, setInputValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      // Update prefix and inputPrefix when preFilledValue changes
      const matchingDropdown = list.find(
        (option) => option.text === preFilledvalue
      );
      setValue(matchingDropdown || "");
      setInputValue(preFilledvalue || "");
      setErrorMessage("");
    }, [preFilledvalue]);

    const handleChange = (e, newDropdown) => {
      setValue(newDropdown);
      setErrorMessage(""); // Clear error when a dropdown is selected
      onDropdownSelect(newDropdown); // Pass the selected dropdown to the parent
    };

    const handleValidation = () => {
      if (required && !dropdown) {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            {label}
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>
        <Autocomplete
          value={dropdown}
          onChange={handleChange}
          inputValue={inputdropdown}
          onInputChange={(e, newInputdropdown) =>
            setInputValue(newInputdropdown)
          }
          options={list}
          getOptionLabel={(option) => option?.text || ""}
          renderInput={(params) => (
            <TextField {...params} placeholder={placeholder} />
          )}
        />
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default Dropdown;
