import React, { useEffect, useState } from "react";
import {
  // Container,
  Grid,
  FormLabel,
  Typography,
  Box,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../Theme";
import { useParams } from "react-router-dom";
import { getCookie } from "../../../utils/utils";

export default function UserPersonal({ activeUserID }) {
  const [userData, setUserData] = useState({});
  //const { activeUserID } = useParams();

  useEffect(() => {
    const fetchUsersAPI = async () => {
      if (!activeUserID) return;
      try {
        setUserData({});
        const token = getCookie();

        const BASE_URL = "https://3.8.158.142:2443/";
        // const URL = `${BASE_URL}api/user/${activeUserID}`;
        const URL = `${BASE_URL}api/personal/${activeUserID}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          setUserData(data.personalData);
        } else {
          console.log("get all failed");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsersAPI();
  }, [activeUserID]);

  const getRow = (leftText = "", rightText = "") => {
    return (
      <>
        <Grid item xs={3} lg={2.5}>
          <FormLabel>
            <Typography variant="h7">{leftText}</Typography>
          </FormLabel>
        </Grid>
        <Grid item xs={0.5}>
          <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
        </Grid>
        <Grid item xs={8.5} lg={9}>
          <Typography variant="body4">{rightText}</Typography>
        </Grid>
      </>
    );
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  // const getTeleCode = (teleCode) => {
  //   if (teleCode) {
  //     //return "+ " + teleCode.split("+").reverse()[0];
  //     return teleCode;
  //   }
  //   return "";
  // };

  const renderSuccesView = () => {
    return (
      <Grid container direction="row" alignItems="top" spacing={"10px"}>
        {getRow(
          "Name",
          `${userData?.prefix || ""} ${userData?.first_name || ""} ${
            userData?.middle_name || ""
          } ${userData?.last_name || ""}`
        )}
        {getRow(
          "Date of Birth",
          `${userData.dob ? formatDate(userData.dob) : ""}`
        )}
        {getRow("Age", `${userData?.age || ""}`)}
        {getRow("Gender", `${userData?.gender || ""}`)}
        {getRow(
          "Contact Number",
          `${
            userData.country_code && userData.contact_number
              ? userData.country_code
              : ""
          } ${userData?.contact_number || ""}`
        )}
        {getRow("Email", `${userData?.email || ""}`)}
        {getRow("Address", `${userData?.address || ""}`)}
        {getRow("City", `${userData?.city || ""}`)}
        {getRow("Postcode", `${userData?.postcode || ""}`)}
        {getRow("Country", `${userData?.country_name || ""}`)}
        {getRow("DBS Number", `${userData?.dbs || ""}`)}
        {getRow(
          "DBS Expiry",
          `${userData.dbs_expiry ? formatDate(userData.dbs_expiry) : ""}`
        )}
        {getRow("Drive", `${userData?.drive ? "Yes" : "No" || ""}`)}
        {userData?.drive
          ? getRow(
              "Licence",
              `${userData?.drive && userData?.licence ? userData?.licence : ""}`
            )
          : ""}
        {userData?.drive
          ? getRow(
              "Licence Expiry",
              `${
                userData?.drive && userData?.drive_expiry
                  ? formatDate(userData.dbs_expiry)
                  : ""
              }`
            )
          : ""}
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={ViewTheme}>
      <Box
        sx={{
          padding: 0,
          width: { xxl: "50%", lg: "70%", xs: "98%" },

          textAlign: "left",
        }}
      >
        {renderSuccesView()}
      </Box>
    </ThemeProvider>
  );
}
