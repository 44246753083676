import React from "react";
import { Box } from "@mui/material";
import {ScreenHeights} from "../users/Theme"

// Mainting the trasparent and non trasparent background with ContentContainers
export default function ContentContainer({
  children,
  isTransparentBg = false,
}) {
  return (
    <Box
      component="div"
      sx={{
        background: isTransparentBg ? "white" : "transparent",
        // backgroundColor:"red",
        borderRadius: "10px",
        border: isTransparentBg ? "2px solid #27998933" : "none",
        boxShadow: isTransparentBg ? "0px 0px 10px 0px #27998933" : "none",
        padding: isTransparentBg? "40px": "0px",
        // display: "flex",
        // justifyContent: "center",
        height: ScreenHeights.contentContainerHeight, // the main content should happens here please do change the 190px if you requires
        overflowY: "auto",
        margin:"0px",
        
      }}
    >
      {children}
    </Box>
  );
}
