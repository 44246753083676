import React, { useState, useEffect } from "react";
import { getCookie } from "../../../utils/utils";
import {
  Grid,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  MenuItem,
  Select,
  FormLabel,
  InputLabel,
  FormControl,
} from "@mui/material";
import { ChooseSessions } from "../../../cms/dropDown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import zIndex from "@mui/material/styles/zIndex";
import {
  Name,
  Calendar,
  NumberInput,
  Currency,
  Dropdown,
} from "../../reusableComponents";
import ClientGridTableEdit from "../../../assets/images/userTableIcons/UserTableEdit.svg";
import ToDo_List from "../../../assets/images/clients/todoList_Icon/ToDo_List.svg";
import ToDo_DownArrow_Icon from "../../../assets/images/clients/todoList_Icon/ToDo_DownArrow_Icon.svg";
import ToDo_UpArrow_Icon from "../../../assets/images/clients/todoList_Icon/ToDo_UpArrow_Icon.svg";
import ToDo_Add_Icon from "../../../assets/images/clients/todoList_Icon/ToDo_Add_Icon.svg";
import ToDo_Search_Icon from "../../../assets/images/clients/todoList_Icon/ToDo_Search_Icon.svg";

const CustomAccordian = ({ todo }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Box
        style={{
          margin: "5px 0px",
          borderRadius: "5px",
          padding: "10px",
          border: "1.5px solid rgba(39, 153, 137, 0.30)",
          background: "#FFF",
          boxShadow: "none !important",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          // background:"green"
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Typography
          variant="body2"
          style={{ padding: 0, flex: 1, textAlign: "left" }}
        >
          {/* {todo.category} */}
          {todo.todo_name}
        </Typography>
        <Typography variant="body2" sx={{ padding: "2px" }}>
          {open ? (
            <ICons imgUrl={ToDo_UpArrow_Icon} />
          ) : (
            <ICons imgUrl={ToDo_DownArrow_Icon} />
          )}
        </Typography>
        {/* <ExpandMoreIcon
          style={{
            marginLeft: 10,
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        /> */}
      </Box>
      {open && (
        <Box
          style={{
            borderRadius: "5px",
            border: "1.5px solid rgba(39, 153, 137, 0.30)",
          }}
        >
          {todo.details.length > 0 ? (
            <>
              {/* Search bar within each section */}
              <div
                style={{
                  borderRadius: "5px 5px 0px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                  background:"#E8F9F6",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={`Search for ${todo.category}`}
                  sx={{
                    height: "30px", // Set desired height

                    "& .MuiInputBase-root": {
                      height: "100%", // Ensures the input field expands to the parent container's height
                      //   padding: "0 14px", // Adjust the padding as needed
                      //   height:""
                      margin: "0",
                      borderRadius: "6px",
                      border: "none",
                      padding: "0",
                      background: "none",
                      outline: "none !important",
                    },
                    outline: "none !important",
                    // margin:0,
                    // padding:0,
                  }}
                  InputProps={{
                    startAdornment: (
                      // <InputAdornment position="start">
                      //   <SearchIcon />
                      // </InputAdornment>
                        <Typography variant="body2" sx={{ padding: "2px" }}>
                        <ICons imgUrl={ToDo_Search_Icon} />
                    </Typography>
                    ),
                  }}
                  style={{ marginBottom: "10px" }}
                />
                
              <Typography variant="body2" sx={{ padding: "2px" }}>
                  <ICons imgUrl={ToDo_Add_Icon} />
              </Typography>
                {/* <SearchIcon style={{ height: 20, width: 20, marginLeft: 5 }} /> */}
              </div>


              {/* Task List */}
              <div style={{ padding: "0px 10px" }}>
                {todo.details.map((task, id) => (
                  <div
                    key={task.id}
                    alignItems="center"
                    style={{
                      borderBottom: "1.5px solid rgba(39, 153, 137, 0.30)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                    //   checked={checkedItems[todo.category]?.[task] || false}
                    //   onChange={() => handleCheck(todo.category, task)}
                    />

                    <Typography
                      variant="body2"
                      style={{ padding: 0, textAlign: "left" }}
                    >
                      {task.todo_description}
                    </Typography>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <Typography>No details available for this category</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

// const predefinedTodos = [
//   {
//     category: "Personal Care",
//     tasks: [
//       "Help the client wake up on time",
//       "Assist with getting out of bed safely",
//       "Guide the client to the bathroom",
//       "Turn on the shower and adjust the water temperature",
//       "Assist with undressing for shower/bath",
//       "Help wash hair with shampoo",
//       "Apply conditioner and rinse hair",
//     ],
//   },
//   {
//     category: "Mobility Assistance",
//     tasks: [
//       "Help the client wake up on time",
//       "Assist with getting out of bed safely",
//       "Guide the client to the bathroom",
//       "Turn on the shower and adjust the water temperature",
//       "Assist with undressing for shower/bath",
//       "Help wash hair with shampoo",
//       "Apply conditioner and rinse hair",
//     ],
//   },
//   {
//     category: "Medication Management",
//     tasks: [
//       "Help the client wake up on time",
//       "Assist with getting out of bed safely",
//       "Guide the client to the bathroom",
//       "Turn on the shower and adjust the water temperature",
//       "Assist with undressing for shower/bath",
//       "Help wash hair with shampoo",
//       "Apply conditioner and rinse hair",
//     ],
//   },
//   { category: "Meal Preparation and Feeding", tasks: [] },
//   { category: "Household Tasks", tasks: [] },
// ];

const TitleComponent = ({ imgUrl, text }) => {
  return (
    <div>
      <img alt="prefdefined" src={imgUrl} style={{ width: "60px" }} />
      <Typography variant="h3" style={{ margin: "10px 0px" }}>
        {text}
      </Typography>
    </div>
  );
};
const ICons = ({ imgUrl }) => {
  return (
    <Box sx={{ width: "16px" }}>
      <img alt="DropDown" src={imgUrl} />
    </Box>
  );
};

const sessionsConstants = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

const CreateTodoForm = ({ togoleCreateTodoPopup }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [allDaysWeekChecked, setAllDaysChecked] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [predefinedTodos, setPredefinedTodos] = useState([]);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        // setTodosList();
        const token = getCookie();

        const BASE_URL = "https://3.8.158.142:2443/";
        const URL = `${BASE_URL}api/todo-get-all-list`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          setPredefinedTodos(data.data);
          console.log(data.data, "data.datadata.datadata.data.......")
        } else {
          console.log("get all failed");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchSessions();
  }, []);

  const handleCheck = (category, task) => {
    setCheckedItems((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [task]: !prev[category]?.[task],
      },
    }));
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const leftSectionJSX = () => {
    return (
      <Grid item xs={4.5}>
        <TitleComponent imgUrl={ToDo_List} text=" Predefined To-Do's" />

        {/* Search Input */}
        <Grid item xs={12}>
          <Typography sx={{ textAlign: "left" }} variant="h6">
            Predefined To-Do’s
          </Typography>
          <TextField
            fullWidth
            placeholder="Search"
            sx={{
              height: "40px", // Set desired height
              "& .MuiInputBase-root": {
                height: "100%", // Ensures the input field expands to the parent container's height
                //   padding: "0 14px", // Adjust the padding as needed
                //   height:""
              },
            }}
            variant="outlined"
          />
        </Grid>

        {/* Predefined To-Do's List */}
        {predefinedTodos.map((todo, index) => (
          <Grid item xs={12} key={index}>
            <CustomAccordian todo={todo} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const rightSectionJSX = () => {
    return (
      <Grid item xs={6.95}>
        <TitleComponent
          imgUrl={ToDo_List}
          text="Added To-Do’s"
        />
        <Grid
          container
          spacing={2}
          sx={{
            //  padding: "20px",
            textAlign: "left",
          }}
        >
          {/* Search Input */}
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "left" }} variant="h6">
              Selected To-Do
            </Typography>
            <TextField
              fullWidth
              placeholder="Search"
              sx={{
                height: "40px", // Set desired height
                "& .MuiInputBase-root": {
                  height: "100%", // Ensures the input field expands to the parent container's height
                  //   padding: "0 14px", // Adjust the padding as needed
                  //   height:""
                },
              }}
              variant="outlined"
            />

            <Box
              style={{
                borderRadius: "8px",
                background: "#D7F9F4",
                fontSize: "12px",
                padding: "4px",
                paddingRight: "12px",
                width: "fit-content",
                position: "relative",
              }}
            >
              selectdd
              <SearchIcon
                style={{
                  height: 10,
                  width: 10,
                  position: "absolute",
                  top: 2,
                  right: 1,
                }}
              />
            </Box>
          </Grid>

          {/* Days of the week selection */}
          <Grid item xs={12} md={5.75}>
            <Typography sx={{ textAlign: "left" }} variant="h6">
              Choose day in a week
            </Typography>
            <Grid container spacing={1}>
              {sessionsConstants.map((day, index) => {
                const isSessionSelected = sessions.includes(day);
                return (
                  <Grid item key={index}>
                    <Box
                      onClick={() => {
                        if (sessions.includes(day)) {
                          setSessions(sessions.filter((each) => each !== day));
                        } else {
                          setSessions([...sessions, day]);
                        }
                      }}
                      key={index}
                      sx={{
                        // background:"red",
                        border: "solid 1px #BFE1DC",
                        padding: "1px !important",
                        margin: "1px",
                        color: "black",
                        textTransform: "capitalize",
                        height: 40,
                        width: 40,
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        background: isSessionSelected ? "#279989" : "none",
                        color: isSessionSelected ? "#FFF" : "#1E3037",
                        fontWeight: isSessionSelected ? 700 : 400,
                        justifyContent: "center",
                        cursor: "pointer",
                        "&:hover": !isSessionSelected && {
                          background: "#DFF1F8", // Change background color on hover
                          color: "#279989", // Change text color on hover
                        },
                      }}
                    >
                      {day}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {/* <FormControlLabel
          control={<Checkbox />}
          label="Select All Days In The Week"
        /> */}
            <Grid item xs={12} sx={{ textAlign: "left", marginTop: "auto" }}>
              <FormControlLabel
                control={<Checkbox checked={true} onChange={() => {}} />}
                label="Select All Days In The Week"
              />
            </Grid>
          </Grid>

          <Grid item md={0.5} xs={false} />
          {/* Sessions selection */}
          <Grid item xs={12} md={5.75}>
            <Grid item xs={12}>
              <Dropdown
                //   ref={personalInfo_marital_statusRef}
                label="Choose Sessions"
                placeholder="Choose Sessions"
                // required={false}
                list={ChooseSessions}
                //   onDropdownSelect={(maritalStatusObj) =>
                //     handleChange(
                //       "personalInfo",
                //       "marital_status",
                //       maritalStatusObj ? maritalStatusObj.text : ""
                //     )
                //   }
                //   preFilledvalue={form.personalInfo.marital_status}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "left", marginTop: "auto" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!allDaysWeekChecked}
                    onChange={(event) => {
                      setAllDaysChecked(event.target.checked);
                    }}
                  />
                }
                label="Select All Sessions In a Day"
              />
            </Grid>
          </Grid>

          {/* Start Date */}
          {/* <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date *"
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid> */}

          {/* End Date */}
          {/* <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid> */}

          <Grid item xs={12} md={5.75}>
            <Calendar
              // ref={empstartdateRef}
              label="Employee Start Date"
              // onDateChange={handleDateChange}
              // required
              // preFilledvalue={payrollInfo.employment_start}
              name="employment_start"
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item xs={12} md={5.75}>
            <Calendar
              // ref={empenddateRef}
              label="Employee End Date"
              // onDateChange={handleDateChange}
              // preFilledvalue={payrollInfo.employment_end}
              name="employment_end"
              // minDate={payrollInfo.employment_start}
            />
          </Grid>
          {/* Additional Notes */}
          <Grid item xs={12}>
            <FormLabel>
              <Typography
                variant="h6"
                sx={{
                  padding: 0,
                  fontFamily: "lora",
                }}
              >
                Additionl Notes
              </Typography>
            </FormLabel>
            <TextField
              name="info"
              variant="outlined"
              className="multiline-textfield"
              multiline
              fullWidth
              rows={5}
              placeholder="Additional Notes"
              // value={competency.info}
              // InputProps={{ readOnly: true }}
            ></TextField>
          </Grid>

          {/* Alert Checkbox */}
          <Grid item xs={12} sx={{ textAlign: "left" }}>
            <FormControlLabel
              control={<Checkbox />}
              label="Check this to get an alert if the ToDo is not completed."
            />
          </Grid>

          {/* Buttons */}
          {/* <Grid item xs={12} container justifyContent="center" spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              togoleCreateTodoPopup();
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success">
            Save
          </Button>
        </Grid>
      </Grid> */}

          <Grid item xs={12} sx={{ mt: 4 }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                // background: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="customcancel"
                onClick={() => {
                  togoleCreateTodoPopup();

                  // if (isFormNotChanged()) {
                  //   toggleEditMode();
                  //   setHasUnsavedChanges(false); // Reset unsaved state
                  // } else {
                  //   handleOpen();
                  // }
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="customsave">
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        position: "fixed",
        display: "flex",
        background: "blue",
        background: "rgba(192, 198, 201, 0.3)",
        zIndex: 9,
        padding: "20px",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          padding: "20px",
          background: "#fff",
          borderRadius: "20px",
          margin: "auto",
          width: "95%",
          height: "90vh",
          overflow: "auto",
          //   height:"100%",
        }}
      >
        {leftSectionJSX()}

        <Grid item md={0.5} xs={false} />
        {/* Sessions selection */}
        {/* <Grid item xs={12} md={5.75}> */}
        {rightSectionJSX()}
      </Grid>
    </Box>
  );
};

export default CreateTodoForm;
