// cookieUtils.js
import Cookies from "js-cookie";

// Get a cookie
export const getCookie = () => {
  return Cookies.get("jwt-token");
};

// Set a cookie
export const setCookie = (value) => {
  Cookies.set("jwt-token", value, { expires: 7 });
};

// Remove a cookie
export const removeCookie = () => {
  Cookies.remove("jwt-token");
};

// Slicing the text with provided text length
export const slicedText = (text, maxTextLength = 20) => {
  if (text.length > maxTextLength) {
    return text.slice(0, maxTextLength) + "...";
  }
  return text;
};
