import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import ArrowDropDownIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const TimeSelector = forwardRef(
  (
    { label, required = false, initialHours = "00", initialMinutes = "00" },
    ref
  ) => {
    const [hours, setHours] = useState(initialHours);
    const [minutes, setMinutes] = useState(initialMinutes);
    const [errorMessage, setErrorMessage] = useState("");

    // Handle Hours Increment and Decrement
    const increaseHours = () => {
      if (Number(hours) < 23) {
        setHours((prev) => String(Number(prev) + 1).padStart(2, "0"));
      }
    };

    const decreaseHours = () => {
      if (Number(hours) > 0) {
        setHours((prev) => String(Number(prev) - 1).padStart(2, "0"));
      }
    };

    const handleHoursChange = (event) => {
      const value = event.target.value;
      if (value >= 0 && value <= 23) {
        setHours(value.padStart(2, "0").slice(-2));
        setErrorMessage(""); // Clear error on valid input
      }
    };

    // Handle Minutes Increment and Decrement
    const increaseMinutes = () => {
      if (Number(minutes) < 59) {
        setMinutes((prev) => String(Number(prev) + 1).padStart(2, "0"));
      }
    };

    const decreaseMinutes = () => {
      if (Number(minutes) > 0) {
        setMinutes((prev) => String(Number(prev) - 1).padStart(2, "0"));
      }
    };

    const handleMinutesChange = (event) => {
      const value = event.target.value;
      if (value >= 0 && value <= 59) {
        setMinutes(value.padStart(2, "0").slice(-2));
        setErrorMessage(""); // Clear error on valid input
      }
    };

    // Validation function
    const handleValidation = () => {
      if (required && hours === "00" && minutes === "00") {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      setErrorMessage(""); // Clear error if validation passes
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
      getTime: () => ({ hours, minutes }), // Function to get the current time
    }));

    return (
      <Box>
        <Typography variant="h6" sx={{ textAlign: "left", marginTop: "5px" }}>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </Typography>

        <Grid container spacing={2} alignItems="Top">
          {/* Hours Section */}
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                value={hours}
                onChange={handleHoursChange}
                inputMode="numeric" // Prevent spinner
                pattern="[0-9]*" // For mobile numeric keyboards
                variant="outlined"
                size="small"
                sx={{ width: "100px", marginBottom: "1px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "-15px",
                          marginRight: "-8px",
                        }}
                      >
                        <IconButton
                          onClick={increaseHours}
                          sx={{
                            marginBottom: "-18px",
                            "&:hover": {
                              background: "none", // No background on hover
                            },
                          }}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                        <IconButton
                          onClick={decreaseHours}
                          sx={{
                            "&:hover": {
                              background: "none", // No background on hover
                            },
                          }}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="body6" sx={{ marginTop: "4px" }}>
                Hours
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Typography variant="h6" sx={{ paddingTop: "12px" }}>
              :
            </Typography>
          </Grid>

          {/* Minutes Section */}
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                value={minutes}
                onChange={handleMinutesChange}
                inputMode="numeric" // Prevent spinner
                pattern="[0-9]*" // For mobile numeric keyboards
                variant="outlined"
                size="small"
                sx={{ width: "100px", marginBottom: "1px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "-15px",
                          marginRight: "-8px",
                        }}
                      >
                        <IconButton
                          onClick={increaseMinutes}
                          sx={{
                            marginBottom: "-18px",
                            "&:hover": {
                              background: "none", // No background on hover
                            },
                          }}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                        <IconButton
                          onClick={decreaseMinutes}
                          sx={{
                            "&:hover": {
                              background: "none", // No background on hover
                            },
                          }}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="body6" sx={{ marginTop: "4px" }}>
                Minutes
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Error Message */}
        {errorMessage && (
          <Typography variant="caption" sx={{ color: "red", marginTop: "8px" }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    );
  }
);

export default TimeSelector;
