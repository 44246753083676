import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Form,
} from "react-bootstrap";
import logo from "../../assets/images/login/c_logo.png";
import LoginImage from "../../assets/images/login/login_img.svg";
import "./login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ElevatorSharp } from "@mui/icons-material";

import Loginconf from "./loginconf";

import { getCookie } from "../../utils/utils";
import { Navigate } from "react-router-dom";
import validator from 'validator';

function Login() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openSuccessGmail, setOpenSuccessGmail] = useState(false);

  const isValidateInputs = () => {
    let isValid = false;
    if (email === "") {
      setError("Email required");
      isValid = false;
    } else if (!validator.isEmail(email)) {
      setError("Please enter a valid email address");
      isValid = false;
    } else {
      setError("");
      isValid = true;
    }

    return isValid;
  };

  const postEmailAPI = async () => {
    setLoading(true);
    const BASE_URL = "https://3.8.158.142:2443/";
    // const BASE_URL = "http://3.8.158.142:3000/";
    const URL = `${BASE_URL}api/send-verification`;
    const body = JSON.stringify({ email });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    };
    const response = await fetch(URL, options);
    const data = await response.json();
    
    if (response.ok === true && response.status === 200) {
      setOpenSuccessGmail(true);
      setError("");
      setLoading(false);
    } else {
      setOpenSuccessGmail(false);
      setError(data.message);
      setLoading(false);
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    if (isValidateInputs()) {
      postEmailAPI();
    }
  };

  const goToLoginBack = () => {
    setOpenSuccessGmail(false);
  };

  if (openSuccessGmail === true) {
    return <Loginconf email={email} goToLoginBack={goToLoginBack} />;
  }

  if (getCookie()) {
    return <Navigate to="/users" />;
  }
  return (
    <div className="Login">
      <Container>
        <Row>
          {/* Left side of the layout: Image and description */}
          <Col lg={6} className="d-none d-lg-flex">
            <Container>
              <Row>
                <Image
                  src={LoginImage}
                  className="Login-image"
                  alt="LoginImage"
                  fluid
                />
              </Row>
              <Row>
                <Col lg={{ span: 11, offset: 1 }} className="jsr">
                  <h3 className="left_side_title mt-4">
                    Caring Hands, Healing Hearts.
                  </h3>
                  <p className="left_side_desc mb-0">
                    The closest thing to being cared for is to care for someone
                    else.
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          {/* Right side of the layout: Login card */}
          <Col lg={{ span: 5, offset: 1 }}>
            <Container>
              <Row>
                <Card className="card">
                  <Row>
                    <Col xs={6}>
                      <Card.Img src={logo} className="Logo-image" alt="Logo" />
                    </Col>
                  </Row>
                  <Card.Body className="card-body">
                    <Row>
                      <Col xs={10}>
                        <Card.Title className="card-title ">Hello!</Card.Title>
                        <Card.Text className="card-text ">
                          Provide email address to receive Magic Link.
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form onSubmit={onSubmitForm}>
                          <Form.Group>
                            <Form.Control
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="inputmail"
                              placeholder="Enter your email address"
                            />
                            {error==='Email doesn\'t exist' ? (<p className="error-message">{'User not found'}</p>) : (<p className="error-message">{error}</p>)}

                            <Button className="sendmagiclink" type="Submit">
                              {loading
                                ? "Please wait  . . ."
                                : "Send Magic Link"}
                            </Button>
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
