import React from 'react'
import Header from '../components/common/header/Header'

const RosterPage = () => {
  return (
    <div>
      <Header/>
      <h1>RosterPage</h1>
    </div>
  )
}

export default RosterPage 