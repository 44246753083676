import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormLabel,
  Autocomplete,
} from "@mui/material";
import vector from "../../../assets/images/clients/Vector.svg";
import { getCookie } from "../../../utils/utils";
import { Name, Email, RadioInput } from "../../reusableComponents";
import ProfilePhotoClose from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoClose.svg";
import ProfilePhotoCloseHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoCloseHover.svg";
import PopupCloseButton from "../clientReusableComponents/PopupCloseButton";

const ShareAccessPopup = ({
  clientId,
  onClose = () => {},
  openDialogue = false,
  // textShow,
  // clientMainData,
  accessAvailed,
}) => {
  const [radio, setRadio] = useState("");
  const [networkInfo, setNetworkInfo] = useState([]);
  const [country, setCountry] = useState("");
  const [inputcountry, setInputCountry] = useState("");
  const [mcnData, setMcnData] = useState("");
  const initialSaMcn = {
    occupation_type: "",
    email: "",
    reason: "",
  };
  const [shareAccessMcn, setShareAccessMcn] = useState(initialSaMcn);

  useEffect(() => {
    const fetchNetwork = async () => {
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/care-network-get-all/${clientId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          const info = data.data;
          setNetworkInfo(info);
        } else {
          setNetworkInfo([]);
        }
      } catch (e) {
        console.error("error", e.message);
      }
    };
    fetchNetwork();
  }, [clientId]);

  const handleClose = () => {
    onClose();
  };
  const handleRadioChange = (rad) => {
    setRadio(rad);
    if (rad === "0") {
      setCountry("");
      setMcnData([]);
      setShareAccessMcn(initialSaMcn);
    } else {
      setMcnData(networkInfo);
      setShareAccessMcn(networkInfo);
    }
  };
  const handleChange = (e, newcountry) => {
    setCountry(newcountry);
    const finalMcn = networkInfo.filter((m) => m.id || 0 === newcountry.id)[0];
    setMcnData(finalMcn);
    setShareAccessMcn({
      occupation_type: finalMcn.occupation_type,
      email: finalMcn.email,
    });
  };
  const handleValueChange = (newValue, name) => {
    setShareAccessMcn((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: newValue,
      };
      // setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };
  const handleEmailChange = (newValue) => {
    setShareAccessMcn((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        email: newValue,
      };
      // setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };
  const handlReasonChange = (event) => {
    setShareAccessMcn((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        reason: event.target.value,
      };
      // setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };
  const confirmShareAccess = async () => {
    if (!clientId) return;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/access?client_id=${clientId}`;
      const token = getCookie();

      console.log("access", accessAvailed);
      console.log("popup mcn", mcnData);

      const accessData = {
        client_id: clientId,
        name: mcnData?.occupation_type || shareAccessMcn.occupation_type,
        email: mcnData?.email || shareAccessMcn.email,
        reason: mcnData?.reason || shareAccessMcn.reason,
        mcnId: mcnData?.id || 0,
        role: "Third Party",
        status: "Active",
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(accessData),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        handleClose();
      } else {
        console.log("issue fetching");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Dialog
      open={openDialogue}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          maxWidth: "415px",
          width: "100%",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex" }}>
        <PopupCloseButton
          buttonText=""
          onClickButton={handleClose}
          imgUrlBeforeHover={ProfilePhotoClose}
          imgUrlAfterHover={ProfilePhotoCloseHover}
          styles={{
            position: "absolute",
            right: "0",
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <img src={vector} alt="Admin" />
          <Grid
            container
            direction="row"
            alignItems="Top"
            sx={{ padding: "10px" }}
          >
            <Grid item md={12} xs={12}>
              <RadioInput
                // ref={lpaRef}
                label="Share Access with Existing Care Network ?"
                name="shareAccess"
                // required
                options={[
                  { value: "1", label: "Yes" },
                  { value: "0", label: "No" },
                ]}
                onValueChange={(val) => handleRadioChange(val)}
                // preFilledvalue={networkInfo.org_lpa_behalf.toString()}
              />
            </Grid>
            {radio === "1" ? (
              <Grid item md={12} xs={12}>
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Autocomplete
                    value={country || ""}
                    onChange={handleChange}
                    inputValue={inputcountry}
                    onInputChange={(e, newInputcountry) =>
                      setInputCountry(newInputcountry)
                    }
                    options={networkInfo.filter(
                      (mcn) => mcn.email !== ""
                      // && !accessAvailed.some((item) => item.status === "Active")
                    )}
                    getOptionLabel={(option) => option?.occupation_type || ""}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="My Care Network" />
                    )}
                  />
                </Box>
              </Grid>
            ) : null}
            <Grid item md={12} xs={12}>
              <Name
                // ref={orgNameRef}
                label="Name/ Organization"
                placeholder="First Name"
                onValueChange={handleValueChange}
                name="occupation_type"
                disabled={mcnData?.occupation_type}
                preFilledvalue={mcnData?.occupation_type}
                required
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Email
                // ref={emailRef}
                disabled={mcnData?.email}
                preFilledvalue={mcnData?.email}
                onEmailValueChange={handleEmailChange}
                name="email"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormLabel>
                <Typography variant="h6">Reason</Typography>
              </FormLabel>
              <TextField
                placeholder="Reason"
                name="reason"
                variant="outlined"
                className="multiline-textfield"
                multiline
                fullWidth
                rows={2}
                onChange={handlReasonChange}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="customcancel"
          onClick={() => handleClose()}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="customsave"
          // onClick={onClickYesDelDialogBtn}
          onClick={confirmShareAccess}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareAccessPopup;
