// ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCookie } from '../utils/utils'; // Assuming you have cookieUtils.js for managing cookies

const ProtectedRoutes = () => {
  const isAuthenticated = !!getCookie();    // Replace 'authToken' with your cookie name

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
