import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Card,
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
} from "@mui/material";
import { Name, Calendar, RadioInput } from "../../reusableComponents"; // Adjust the path
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import Admin from "../../../assets/images/users/Admin.svg";
import { ViewTheme } from "../../../themes/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../../themes/Theme";
import EYE from "../../../assets/images/users/view.svg";
import { getCookie } from "../../../utils/utils";
import InformationDialog from "../../reusableComponents/informationDialog";

const AssessmentFormView = ({ clientId, name, assessmentName }) => {
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);

  useEffect(() => {
    const fetchAssessmentQuestions = async () => {
      //if (!clientId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/careplan-assessment-questions-list`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          // Map the response to transform it as required
          const modifiedAssessmentList = data.finalData.map((assessment) => ({
            assessment_name: assessment.assessment_name,
            questionslist: assessment.questions.map((question) => ({
              questionid: question.id,
              questionname: question.question_name.replace("<name>", name),
            })),
          }));

          // Update the state with the modified data
          setAssessmentQuestions(modifiedAssessmentList);
          console.log(
            "modifiedAssessmentList",
            modifiedAssessmentList.assessment_name
          );
        } else {
          setAssessmentQuestions([]);
        }
      } catch (e) {
        console.log("Error fetching assessment questions:", e.message);
        setAssessmentQuestions([]);
      }
    };
    fetchAssessmentQuestions();
  }, []);
  useEffect(() => {
    console.log("assessmentQuestions", assessmentQuestions);

    assessmentQuestions.forEach((assessment, index) => {
      console.log(`Assessment ${index + 1} Name:`, assessment.assessment_name);
      assessment.questionslist.forEach((question) => {
        console.log("Question Name:", question.questionname);
      });
    });
  }, [assessmentQuestions]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        <ClientHeader head={`${name}'s Care Plan`} isHideButton={true} />
        <Button
          variant="outlined"
          color="secondary"
          //startIcon={<EditIcon />}
          //onClick={toggleEditMode}
          sx={{
            margin: 0,
            height: "35px",
            position: "absolute",
            top: "16px",
            right: "25px",
            zIndex: 11,
          }}
        >
          New Assessment
        </Button>
        {/* <Button
          variant="outlined"
          color="secondary"
          //startIcon={<EditIcon />}
          //onClick={toggleEditMode}
          sx={{
            margin: 0,
            height: "35px",
            position: "absolute",
            top: "16px",
            right: "325px",
            zIndex: 11,
          }}
        >
          Edit
        </Button> */}
        <Box sx={{ marginTop: "20px" }}>
          <Grid container direction="row" alignItems="top">
            <Grid item lg={9} xs={8}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(100vh - 310px)",
                  paddingRight: 2,
                  margin: 0,
                  marginRight: "20px",
                }}
              >
                <Box
                  sx={{
                    textAlign: "Left",
                    height: "100%",
                    overflow: "auto",
                    paddingRight: 2,
                    width: "100%",
                  }}
                >
                  {assessmentQuestions.length > 0 ? (
                    assessmentQuestions
                      .filter(
                        (assessment) =>
                          assessment.assessment_name === assessmentName
                      )
                      .map((assessment, index) => (
                        <Box key={index}>
                          <Typography variant="h4">
                            {assessment.assessment_name}
                          </Typography>
                          {assessment.questionslist.map((question) => (
                            <p key={question.questionid}>
                              {question.questionname}
                            </p>
                          ))}
                        </Box>
                      ))
                  ) : (
                    <p>No assessment questions available</p>
                  )}
                </Box>
              </Card>
            </Grid>
            <Grid item lg={3} xs={4}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "calc(100vh - 310px)",
                  margin: 0,
                  marginLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    padding: ViewTheme.spacing(2, 1),
                    width: "100%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      backgroundColor: "#279989",
                      color: "#fff",
                      fontFamily: "Arvo",
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "8px 5px",
                    }}
                  >
                    Historical Assessments
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AssessmentFormView;
