import { Typography, Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";
import { useNavigate } from "react-router-dom";
import { TabButton, UserListBox } from "../userPersonal/Components";
import { getCookie } from "../../../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import UserNextToKinEdit from "../userNextToKin/UserNextToKinEdit";
import UserNextToKinView from "../userNextToKin/UserNextToKinView";
import UserPayroll from "../userpayroll/UserPayroll";
import UserPayrollEdit from "../userpayroll/UserPayrollEdit";
import UserCompetency from "../userCompetency/UserCompetency";
import UserCompetencyEdit from "../userCompetency/UserCompetencyEdit";
import UserAccessibility from "../userAccessibility/UserAccessibility";
import UserAccessibilityEdit from "../userAccessibility/UserAccessibilityEdit";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import { UserHeader } from "../commonComponents/Components";
import UserPersonalView from "../userPersonal/UserPersonalView";
import TopContentContainer from "../../reusableComponents/TopContentContainer";

const tabsList = [
  { id: 1, text: "Personal" },
  { id: 2, text: "Next to Kin" },
  { id: 3, text: "Payroll" },
  { id: 4, text: "Accessibility" },
  { id: 5, text: "Competency" },
];

export default function UserDashMain() {
  const { userID } = useParams();
  const navigate = useNavigate();
  const [tempUserID, setTempUserID] = useState(userID);
  const [searchParams, setSearchParams] = useSearchParams(); // Used for URL params
  const [activeUserID, setActiveUserID] = useState(userID);
  const [activeUserName, setActiveUserName] = useState("");
  const [activeFullName, setFullActiveName] = useState("");
  const initialTab = parseInt(searchParams.get("tab")) || tabsList[0].id;
  const [activeTab, setActiveTab] = useState(initialTab);
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingTabId, setPendingTabId] = useState(null);
  const [pendingUserId, setPendingUserId] = useState(null);
  const [pendingNavigation, setPendingNavigation] = useState(false);
  const [userData, setUserData] = useState({});

  const isEditQueryParams = searchParams.get("edit");
  const initialMode = searchParams.get("mode")
    ? searchParams.get("mode")
    : isEditQueryParams
    ? "Edit"
    : "View";

  const [isEditMode, setIsEditMode] = useState(initialMode);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useOutletContext();

  const updateModeToView = () => setIsEditMode("View");

  useEffect(() => {
    const fetchUsersAPI = async () => {
      try {
        setUserData({});
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/personal/${tempUserID}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok) {
          setUserData(data.personalData);
        } else {
          console.log("get all failed");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsersAPI();
  }, [tempUserID]); // Fetch data when tempUserID changes
  // Update activeUserID and names when userData changes
  useEffect(() => {
    // Use the id parameter or other logic
    if (userData) {
      setActiveUserID(userData.user_id);
      let name = "";
      let firstname = "";
      if (userData?.first_name && userData?.last_name) {
        name = `${userData?.first_name} ${userData?.last_name}`;
        firstname = `${userData?.first_name} `;
      }
      setActiveUserName(firstname);
      setFullActiveName(name);
    }
  }, [userData]);

  useEffect(() => {
    updateUrlParams(activeTab, isEditMode);
  }, [tempUserID]); // Run this effect when tempUserID changes

  // Update the URL when tab or mode changes
  const updateUrlParams = (tabId, mode) => {
    setSearchParams({ tab: tabId, mode: mode });
  };

  const toggleEdit = () => {
    const newMode = isEditMode === "View" ? "Edit" : "View";
    setIsEditMode(newMode);
    updateUrlParams(activeTab, newMode);
  };

  const showToastMessage = (msg) => {
    toggleEdit();
    toast.success(msg, { position: "top-right" });
  };
  const handleBackButtonClick = () => {
    if (hasUnsavedChanges) {
      setPendingNavigation(true);
      handleOpenDialog();
    } else {
      navigate("/users");
    }
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPendingTabId(null);
    setPendingUserId(null);
    setPendingNavigation(false);
  };

  // Handle tab switching with unsaved changes confirmation
  const handleTabSwitch = (tabId) => {
    if (hasUnsavedChanges) {
      setPendingTabId(tabId);
      handleOpenDialog();
    } else {
      setActiveTab(tabId);
      updateUrlParams(tabId, isEditMode);
    }
  };

  const updateActiveUserID = (newUserID) => {
    if (hasUnsavedChanges) {
      setPendingUserId(newUserID);
      handleOpenDialog();
    } else {
      //updateUrlParams(activeTab, isEditMode);
      setTempUserID(newUserID);
    }
  };

  const onClickYesDialogBtn = () => {
    if (pendingTabId !== null) {
      setActiveTab(pendingTabId);
      updateUrlParams(pendingTabId, isEditMode);
      setPendingTabId(null);
    }
    if (pendingUserId !== null) {
      setTempUserID(pendingUserId);
      //updateUrlParams(activeTab, isEditMode); // Ensure mode and tab are updated when user switches
      setPendingUserId(null);
    }
    if (pendingNavigation) {
      navigate("/users"); // Navigate after confirming
      setPendingNavigation(false);
    }
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    handleCloseDialog();
  };

  // Confirm switch and finalize user change
  const confirmUserChange = () => {
    setActiveUserID(tempUserID); // Only set activeUserID when confirmed
  };

  // const activeTabText = () =>
  //   tabsList.filter((each) => each.id === activeTab)[0].text;

  const activeTabText = () => {
    const tab = tabsList.filter((each) => each.id === activeTab)[0].text;
    if (tab === "Personal") {
      return "Personal Details";
    }
    return tab ? tab : "";
  };

  const headerText = () => {
    // return `${activeUserName ? activeUserName : "User"}'s ${activeTabText()}`;
    return `${activeUserName ? activeUserName : ""}'s ${activeTabText()}`;
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <UserPersonalView
            activeUserID={activeUserID}
            isEditMode={isEditMode}
            activeFullName={activeFullName}
            updateModeToView={updateModeToView}
            setHasUnsavedChanges={setHasUnsavedChanges}
            userData={userData}
          />
        );

      case 2:
        return (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              {isEditMode === "Edit" ? (
                <UserNextToKinEdit
                  userId={activeUserID}
                  toggleEdit={toggleEdit}
                  toastMsg={showToastMessage}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              ) : (
                <UserNextToKinView userId={activeUserID} />
              )}
            </div>
          </div>
        );

      case 3:
        return (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              {isEditMode === "Edit" ? (
                <UserPayrollEdit
                  userId={activeUserID}
                  toggleEdit={toggleEdit}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              ) : (
                <UserPayroll userId={activeUserID} />
              )}
            </div>
          </div>
        );

      case 4:
        return (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              {isEditMode === "Edit" ? (
                <UserAccessibilityEdit
                  userId={activeUserID}
                  toggleEdit={toggleEdit}
                  toastMsg={showToastMessage}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              ) : (
                <UserAccessibility userId={activeUserID} />
              )}
            </div>
          </div>
        );
      case 5:
        return isEditMode === "Edit" ? (
          <UserCompetencyEdit
            userId={activeUserID}
            activeUserName={activeUserName}
            toggleEdit={toggleEdit}
            toastMsg={showToastMessage}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        ) : (
          <UserCompetency userId={activeUserID} />
        );
      default:
        return null; // or a default component
    }
  };

  return (
    <Box>
      <TopContentContainer>
        <Typography
          component="p"
          sx={{ color: "#279989", fontWeight: 700, padding: "0px" }}
        >
          {`User > ${
            activeUserName ? activeUserName : "User"
          } > ${activeTabText()}`}
        </Typography>
        {/* <Link to="/users" style={{ textDecoration: "none" }}> */}
        <CustomButton text="Back" onClick={handleBackButtonClick} />
        {/* </Link> */}
      </TopContentContainer>
      <br />

      <Box
        component="div"
        sx={{
          background: "white",
          width: "100%",
          position: "relative",
          borderRadius: "15px",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translate(-50%, -100%)",
            zIndex: 1,
          }}
        >
          {tabsList.map((each) => (
            <TabButton
              key={each.id}
              isActive={activeTab === each.id}
              onClick={() => handleTabSwitch(each.id)}
              data={each}
            />
          ))}
        </Box>

        <Box
          sx={{
            padding: 3,
            borderRadius: "10px",
            height: "calc(100vh - 220px)",
            boxShadow: "0px 0px 10px 0px #27998933",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ width: "20%", background: "white", height: "100%" }}>
              <UserListBox
                activeUserID={activeUserID}
                updateActiveUserID={updateActiveUserID} // Updated function
              />
            </div>

            <div
              style={{
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                width: "80%",
                paddingLeft: "5px",
                paddingRight: "20px",
              }}
            >
              <UserHeader
                toggleEdit={toggleEdit}
                head={headerText()}
                mode={isEditMode}
              />

              {renderActiveTabContent()}
            </div>
          </div>
        </Box>
      </Box>
      <ToastContainer />
      <ConfirmationDialog
        openDialog={openDialog}
        TitleText="Do you want to leave without saving changes?"
        paraText="Any unsaved changes will be lost."
        IconUrl={Admin}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancelFun={handleCloseDialog}
        onConfirmFun={onClickYesDialogBtn}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
}
