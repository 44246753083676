import React, { useState } from "react";
import TopContentContainer from "../../reusableComponents/TopContentContainer";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CustomButtom from "../../reusableComponents/CustomButton";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../../../themes/Theme";
import ContentContainer from "../../reusableComponents/ContentContainer";
import TodoTable from "./todoTable/TodoTable";

import { ClientHeader } from "../../clients/clientReusableComponents/components";
import CreateTodoForm from "./CreateTodoForm";
import { heIL } from "@mui/x-date-pickers/locales";

export const TodoDashBord = () => {
  const [openCreateTodo, setOpenCreateTodo] = useState(false);
  const togoleCreateTodoPopup = () => {
    setOpenCreateTodo(!openCreateTodo);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height:"100%",
        borderRadius: "10px",
        padding: "20px 30px",

        // height: "calc(100vh - 200px)",
        // overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px",
          paddingBottom: "15px",
        }}
      >
        <div style={{ flex: 1 }}>
          <ClientHeader head={`Nolan's To-Dos`} isHideButton={true} />
        </div>
        <CustomButtom
          text=" + Create To-Do"
          onClick={() => togoleCreateTodoPopup()}
        />

        {openCreateTodo && (
          <CreateTodoForm togoleCreateTodoPopup={togoleCreateTodoPopup} />
        )}
      </Box>

      <ContentContainer>
        <TodoTable />
      </ContentContainer>
    </Box>
  );
};
