import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid, Button, Box } from "@mui/material";
//import CarePlanView from "./CarePlanView";
import CarePlanEdit from "./CarePlanEdit";
import { CarePlanSideBar } from "./CarePlanSideBar";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentFormView from "./AssessmentFormView";

const CarePlan = ({
  clientId,
  clientMainData,
  toggleEdit,
  setHasUnsavedChanges,
  //isEditMode,
}) => {
  const [activeTabID, setActiveTabID] = useState(0);
  const [name, setName] = useState("");
  const [assessmentName, setAssessmentName] = useState("");
  const [searchParams] = useSearchParams();
  const isEditQueryParams = searchParams.get("edit");
  const [isEditMode, setIsEditMode] = useState(
    isEditQueryParams ? true : false
  );
  const toggleEditMode = (latestTabId) => {
    // setActiveTabID(latestTabId)
    setIsEditMode(!isEditMode);
  };
  useEffect(() => {
    if (clientMainData) {
      setName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);

  return (
    <Grid container direction="row" alignItems="top">
      <Box
        sx={{
          padding: 3,
          borderRadius: "10px",
          height: "calc(100vh - 220px)",
          boxShadow: "0px 0px 10px 0px #27998933",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ width: "20%", height: "100%" }}>
            <CarePlanSideBar
              clientId={clientId}
              clientMainData={clientMainData}
              //setTabId={setTabId}
              activeTabID={activeTabID}
            />
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              overflow: "auto",
              width: "80%",
              paddingLeft: "25px",
              paddingRight: "15px",
              position: "relative",
            }}
          >
            {!assessmentName && (
              <CarePlanEdit
                toggleEditMode={toggleEditMode}
                clientMainData={clientMainData}
                sideTabId={activeTabID}
                setHasUnsavedChanges={setHasUnsavedChanges}
                setAssessmentName={setAssessmentName}
              />
            )}
            {assessmentName && (
              <AssessmentFormView
                clientId={clientId}
                name={name}
                assessmentName={assessmentName}
              />
            )}
          </div>
        </div>
      </Box>
    </Grid>
  );
};

export default CarePlan;
