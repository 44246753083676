import React from 'react'

const ClientSetting = () => {
  return (
    <div>
        <h1>ClientSetting</h1>
    </div>
  )
}

export default ClientSetting