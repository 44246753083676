import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Grid, Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme, ViewTheme } from "../../../themes/Theme";
import { getCookie } from "../../../utils/utils";
import Admin from "../../../assets/images/users/Admin.svg";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import ContactNumber from "../../reusableComponents/Contact";
import Prefix from "../../reusableComponents/Prefix";
import Name from "../../reusableComponents/Name";
import Email from "../../reusableComponents/Email";
import Country from "../../reusableComponents/Country";
import Dropdown from "../../reusableComponents/Dropdown";
import { risklevel } from "../../../cms/dropDown";

const initialForm = {
  prefix: "",
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  risklevel: "",
  address: "",
  city: "",
  postcode: "",
  country: "",
  telephoneCodes: "",
};

export default function AddClientForm() {
  const [form, setForm] = useState(initialForm);
  const [startingForm, setStartingForm] = useState(form);

  const contactRef = useRef();
  const prefixRef = useRef();
  const firstNameRef = useRef();
  const middleNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const risklevelRef = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const postCodeRef = useRef();
  const countryRef = useRef();

  const [selectedCountry, setSelectedCountry] = useState(217);

  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const [emailExistsError, setEmailExistError] = useState("");
  const [contactExistsError, setContactExistsError] = useState("");

  const showToastMessage = () => {
    toast.success("Client added successfully", {
      position: "top-right",
      autoClose: 2000, // Duration in milliseconds
    });
  };

  const showToastFailureMessage = () => {
    toast.error("Failed to add client", {
      position: "top-right",
    });
  };

  // Handle country selection in the Contact component
  const handleCountrySelect = (country) => {
    if (country !== null) {
      setSelectedCountry(country.id);
    }
    setContactExistsError("");
  };

  const onChangeContactNumber = (contactVal) => {
    setForm({ ...form, phoneNumber: contactVal });
  };

  // Handle Prefix selection
  const onChangePrefixVal = (prefixVal) => {
    if (prefixVal !== null) {
      setForm({ ...form, prefix: prefixVal.text });
    } else {
      setForm({ ...form, prefix: "" });
    }
  };

  // //Handle Email
  const onChangeEmail = (emailValue) => {
    setForm({ ...form, email: emailValue });
    setEmailExistError("");
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onClickYesDialogBtn = () => {
    navigate("/clients");
  };

  const checkIsValidsGiven = () => {
    // let errorData = {
    //   prefixError: "",
    //   firstNameError: "",
    //   middleNameError: "",
    //   lastNameError: "",
    //   phoneNumberError: "",
    //   emailError: "",
    //   risklevelError: "",
    //   addressError: "",
    //   cityError: "",
    //   postcodeError: "",
    //   countryError: "",
    // };

    // Call the handleValidation method from the child components
    // prefix validation
    if (prefixRef.current) {
      prefixRef.current.handleValidation();
    }

    // first name validation
    if (firstNameRef.current) {
      firstNameRef.current.handleValidation();
    }

    // last name validation
    if (lastNameRef.current) {
      lastNameRef.current.handleValidation();
    }

    // phone number validation
    if (contactRef.current) {
      contactRef.current.handleValidation();
    }

    // email validation
    if (emailRef.current) {
      emailRef.current.handleValidation();
    }
    // risklevel validation
    if (risklevelRef.current) {
      risklevelRef.current.handleValidation();
    }
    // address validation
    if (addressRef.current) {
      addressRef.current.handleValidation();
    }
    // city validation
    if (cityRef.current) {
      cityRef.current.handleValidation();
    }
    // postcode validation
    if (postCodeRef.current) {
      postCodeRef.current.handleValidation();
    }
    // country validation
    if (countryRef.current) {
      countryRef.current.handleValidation();
    }

    return (
      prefixRef.current.handleValidation() &&
      firstNameRef.current.handleValidation() &&
      lastNameRef.current.handleValidation() &&
      contactRef.current.handleValidation() &&
      emailRef.current.handleValidation() &&
      risklevelRef.current.handleValidation() &&
      addressRef.current.handleValidation() &&
      cityRef.current.handleValidation() &&
      cityRef.current.handleValidation() &&
      countryRef.current.handleValidation()
    );
  };

  const postUserAPI = async () => {
    try {
      const token = getCookie();
      setEmailExistError("");
      setContactExistsError("");

      const body = {
        prefix: form.prefix,
        first_name: form.firstName,
        middle_name: form.middleName,
        last_name: form.lastName,
        telephone_codes: selectedCountry,
        contact_number: form.phoneNumber,
        email: form.email,
        risk_level: form.risklevel,
        address: form.address,
        city: form.city,
        postcode: form.postcode,
        country: form.country,
      };

      console.log(body, "post api body................");
      const BASE_URL = "https://3.8.158.142:2443/";
      const URL = `${BASE_URL}api/client`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        showToastMessage();

        setTimeout(() => {
          navigate("/clients");
        }, 3000); // Delay in milliseconds
      } else {
        // email already exists
        if (data.message === "Email already exists") {
          setEmailExistError(data.message);
        }

        // contact already exists
        if (data.message === "Contact number already exists") {
          setContactExistsError(data.message);
        }

        showToastFailureMessage();
        // console.log("Failed to create user.");
      }
    } catch (error) {
      showToastFailureMessage();
      // console.error("An error occurred:", error);
    }
  };

  
  const postUser = () => {
    if (checkIsValidsGiven() === true) {
      postUserAPI();
    } else {
      handleClose();
    }
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    postUser();
  };

  const elementWidth = {
    // width: "22%",
    width: { xs: "100%", md: "45%", lg: "22%" },
    gap: 1,
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          //width: { md: "75%", xs: "90%" },
        }}
        maxWidth={false}
      >
        <Box
          sx={{ elementWidth }}
          onSubmit={onSubmitForm}
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid container direction="row" alignItems="Top">
            <Grid item xs={12} md={1.5}>
              <Prefix ref={prefixRef} onPrefixSelect={onChangePrefixVal} />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                label={"First Name"}
                placeholder={"First Name"}
                onValueChange={(value) =>
                  setForm({ ...form, firstName: value })
                }
                required={true}
                ref={firstNameRef}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                label={"Middle Name"}
                placeholder={"Middle Name"}
                onValueChange={(value) =>
                  setForm({ ...form, middleName: value })
                }
                required={false}
                ref={middleNameRef}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                label={"Last Name"}
                placeholder={"Last Name"}
                onValueChange={(value) => setForm({ ...form, lastName: value })}
                required={true}
                ref={lastNameRef}
              />
            </Grid>

            <Grid item md={8.5} xs={12}>
              <ContactNumber
                onValueChange={onChangeContactNumber}
                onCountrySelect={handleCountrySelect}
                // defaultCountry={selectedCountry} // Pass the default country value
               preFilledcode="+44"
                ref={contactRef}
                contactExistsError={contactExistsError}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Dropdown
                ref={risklevelRef}
                label="Risk Level"
                placeholder="Risk Level"
                required={true}
                list={risklevel}
                onDropdownSelect={(risklevelObj) => {
                  setForm({
                    ...form,
                    risklevel: risklevelObj ? risklevelObj?.text : "",
                  });
                }}
                preFilledvalue={form.risklevel}
              />
            </Grid>

            <Grid item xs={12}>
              <Email
                onEmailValueChange={onChangeEmail}
                ref={emailRef}
                required
                emailExistsError={emailExistsError}
              />
            </Grid>

            <Grid item xs={12}>
              <Name
                label={"Address"}
                name={"address"}
                placeholder={"Address"}
                onValueChange={(value) => setForm({ ...form, address: value })}
                required={true}
                AllCapital={false}
                ref={addressRef}
                preFilledvalue={form.address}
              />
            </Grid>
            <Grid item xs={12} lg={3.5}>
              <Name
                label={"City"}
                name={"city"}
                placeholder={"City"}
                onValueChange={(value) => setForm({ ...form, city: value })}
                required={true}
                AllCapital={false}
                ref={cityRef}
                preFilledvalue={form.city}
              />
            </Grid>
            <Grid item lg={0.75} xs={false} />
            <Grid item xs={12} lg={3.5}>
              <Name
                label={"Postcode"}
                name={"postCode"}
                placeholder={"Postcode"}
                onValueChange={(value) => setForm({ ...form, postcode: value })}
                required={true}
                AllCapital={true}
                ref={postCodeRef}
                preFilledvalue={form.postcode}
              />
            </Grid>
            <Grid item lg={0.75} xs={false} />
            <Grid item xs={12} lg={3.5}>
              <Country
                ref={countryRef}
                required
                onCountrySelect={(countryObj) => {
                  setForm({
                    ...form,
                    country: countryObj ? countryObj.id : "",
                  });
                }}
                name=""
                preFilledvalue={form.country_name}
              />
            </Grid>

            <Grid item xs={12} lg={6} sx={{ mt: 4 }}>
              <Box sx={{ width: "fit-content" }}>
                <Button
                  variant="customcancel"
                  onClick={() => {
                    if (JSON.stringify(startingForm) === JSON.stringify(form)) {
                      navigate("/clients");
                    } else {
                      handleOpen();
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="customsave">
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Confirmation Dialog */}
        {openDialog && (
          <ConfirmationDialog
            openDialog={openDialog}
            TitleText="Are you sure you want to exit without saving the changes?"
            paraText="This will disable web application access to the client."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Yes"
            onCancelFun={() => {
              // navigate("/clients");
              handleClose();
            }}
            onConfirmFun={onClickYesDialogBtn}
            handleCloseDialog={handleClose}
          />
        )}

        <ToastContainer />
      </Container>
    </ThemeProvider>
  );
}
