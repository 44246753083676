import React, { useState, useRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import {
  Prefix,
  Name,
  Email,
  ContactNumber,
  Calendar,
  Days,
  Time,
  RadioInput,
  NumberInput,
  Country,
} from "../../reusableComponents";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import AddVisit from "../../../assets/images/clients/Visits/AddVisit.svg";

export default function AssVisitDialog({
  openDialog,
  handleCloseDialog = () => {},
}) {
  // Custom handler for closing the dialog
  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  const onCancelFun = () => {
    handleCloseDialog();
  };
  const daysRef = useRef(null); // Use ref to reference the Days component
  const onConfirmFun = () => {
    if (daysRef.current && daysRef.current.handleValidation()) {
      // If validation passes, proceed
      console.log("Form is valid");
    } else {
      console.log("Form is invalid");
    }
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "50%", // Set your desired width here
          maxWidth: "900px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img
              style={{
                width: "10%",
                height: "auto",
              }}
              src={AddVisit}
              alt="Add Visit"
            />
            <Typography variant="h3">Add Visits</Typography>
          </Box>

          <Box>
            <Grid container direction="row" alignItems="Top">
              <Grid item xs={12} md={5.75}>
                <Calendar
                  //ref={dobRef}
                  label="Start Date"
                  //onDateChange={handlekDateChange}
                  //preFilledvalue={kinInfo.dob}
                  //maxDate={currentDate}
                  required
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={5.75}>
                <Calendar
                  //ref={dobRef}
                  label="End Date"
                  //onDateChange={handlekDateChange}
                  //preFilledvalue={kinInfo.dob}
                  //maxDate={currentDate}
                  //required
                />
              </Grid>
              <Grid item xs={12} md={12} lg={5.75} sx={{ marginTop: "5px" }}>
                <Days
                  //label="Select your preferred days"
                  //initialSelectedDays={["Mon", "Wed"]}
                  //   onDaysChange={handleDaysChange}
                  //   selectAllLabel="Select all days"
                  //ref={daysRef} // Attach ref
                  required
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={12} lg={5.75}>
                <NumberInput
                  //ref={dobRef}
                  label="No.of Carers Required"
                  //onDateChange={handlekDateChange}
                  //preFilledvalue={kinInfo.dob}
                  //maxDate={currentDate}
                  required
                  enableSpinner
                />
              </Grid>
              <Grid item xs={12} md={5.75}>
                <Time label="Start Time" />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={5.75}>
                <Time label="End Time" />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={onConfirmFun} color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
