import React from "react";
import { Grid, Card, Typography } from "@mui/material";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import { getCookie } from "../../../utils/utils";

const ShareAccessCard = ({ accessAvailed, clientId }) => {
  const handleRevoke = async () => {
    if (!clientId) return;
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/access?client_id=${clientId}&id=${accessAvailed.id}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok) {
        let sharedIds = data.data;
        console.log("Shared ids", sharedIds);
      } else {
        console.log("issue");
      }
    } catch (e) {
      console.error("Error fetching Share Access", e.message);
    }
  };
  const styles = {
    colomn: {
      fontSize: "16px",
      fontWeight: 505,
      fontFamily: "Arvo",
      color: "#279989",
    },
    data: { color: "#1E3037", fontWeight: 400 },
  };
  return (
    <Card
      sx={{
        maxWidth: 425,
        background: "#FFF",
        padding: "10px",
      }}
    >
      <Grid container direction="row">
        <Grid item xs={9.5} md={9.5}>
          {accessAvailed?.name !== "" ? (
            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
              <Typography variant="h6" sx={styles.colomn}>
                Name:{" "}
                <span style={styles.data}>{accessAvailed?.name || ""}</span>
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Typography variant="h6" sx={styles.colomn}>
              Email ID:{" "}
              <span style={styles.data}>{accessAvailed?.email || ""}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Typography variant="h6" sx={styles.colomn}>
              Active Since:{" "}
              <span style={styles.data}>
                {accessAvailed?.created_at?.split("T")[0] || ""}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={2.5} md={2.5}>
          <CustomButton
            style={
              accessAvailed?.status === "Active"
                ? {
                    background: "#F1FFF3",
                    color: "#03AE71",
                    border: "1.5px solid #03AE71",
                    alignItems: "right",
                  }
                : {
                    background: "#FFF0E3",
                    color: "#FA8733",
                    border: "1.5px solid #F5891D",
                  }
            }
            text={accessAvailed?.status || ""}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <br />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CustomButton
            text="Resend Access"
            disabled={accessAvailed?.status === "Inactive"}
            // onClick={() => handleRevoke(accessAvailed)}
          />
          <CustomButton
            disabled={accessAvailed?.status === "Inactive"}
            text="Revoke Access"
            onClick={() => handleRevoke()}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ShareAccessCard;
