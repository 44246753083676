import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../reusableComponents/CustomButton";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../../themes/Theme";
import AddVisitDialog from "./AddVisitDialog";

export const VisitsDashboard = ({ clientId, clientMainData }) => {
  const [name, setName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  // Array of day labels
  const dayLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // Array of time slots
  const timeSlots = ["Morning", "Afternoon", "Tea", "Dinner", "Night"];
  // Array of time ranges
  const times = [
    "07:00-11:00",
    "11:00-15:00",
    "15:00-18:00",
    "18:00-22:00",
    "22:00-07:00",
  ];
  const visitCardHeight = "calc((100vh - 400px)/5)";

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  useEffect(() => {
    if (clientMainData) {
      setName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          padding: "20px 30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "15px",
          }}
        >
          <div style={{ flex: 1 }}>
            <ClientHeader head={`${name}'s Visits`} isHideButton={true} />
          </div>
          <CustomButton text=" + Add Visit" onClick={() => handleOpen()} />
          <Box sx={{ marginLeft: "20px", marginRight: "6px" }}>
            <CustomButton
              text="Cancel Multiple Visits"
              // onClick={() => handleOpen()}
              disabled
            />
          </Box>
        </Box>

        {/* Container for Header and Time Slots */}
        <Box>
          {/* Header Row */}
          <Box sx={{ marginRight: "6px" }}>
            <Grid
              container
              direction="row"
              alignItems="top"
              sx={{
                border: "1px solid #279989",
                height: "35px",
                backgroundColor: "#279989",
                color: "white",
                borderRadius: "10px 10px 0 0",
                marginBottom: "0px",
              }}
            >
              <Grid item xs={1.5}></Grid>
              {dayLabels.map((day) => (
                <Grid
                  key={day}
                  item
                  xs={1.5}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginRight: "0px",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "white" }}>
                    {day}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Time Slots Rows */}
          <Box
            sx={{
              height: "calc(100vh - 355px)", // Adjust this as necessary
              overflowY: "scroll",
            }}
          >
            {timeSlots.map((timeSlot, index) => (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                key={timeSlot}
              >
                <Grid
                  item
                  xs={1.5}
                  sx={{
                    borderRight: "1px solid #279989", // Add border to all sides
                    borderLeft: "1px solid #279989", // Add border to all sides
                    borderBottom: "1px solid #279989", // Add border to all sides
                    minHeight: "105px",
                    height: visitCardHeight,
                    display: "flex",
                    flexDirection: "column", // Change to column to stack elements
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#e0f2f1",
                    marginBottom: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Typography variant="h6">{timeSlot}</Typography>
                  {/* Displaying the corresponding time for the time slot */}
                  <Box sx={{ fontSize: "12px" }}>
                    {" "}
                    {/* Adjust font size as needed */}
                    <Typography variant="h6">{times[index]}</Typography>
                  </Box>
                </Grid>
                {dayLabels.map((_, dayIndex) => (
                  <Grid
                    key={`${index}-${dayIndex}`}
                    item
                    xs={1.5}
                    sx={{
                      borderRight: "1px solid #279989", // Add border to all sides
                      borderBottom: "1px solid #279989", // Add border to all sides
                      minHeight: "105px",
                      height: visitCardHeight,
                      textAlign: "center",
                      marginRight: "0px",
                      marginBottom: "0px",
                      backgroundColor: "#ffffff", // Ensures visibility of the grid lines
                    }}
                  >
                    {/* Placeholder for time slots */}
                  </Grid>
                ))}
              </Grid>
            ))}
          </Box>
        </Box>
        {openDialog && (
          <AddVisitDialog
            openDialog={openDialog}
            competencyId={0}
            handleCloseDialog={handleClose}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};
