import React, { useState, useEffect } from "react";
import { ClientAvatarForm } from "../aboutMe/ClientAvatarForm";
import {
  Box,
  Typography,
  Grid,
  Button,
  Autocomplete,
  FormLabel,
  TextField,
} from "@mui/material";
import { getCookie } from "../../../utils/utils";
import { ClientSideBarHeader } from "../clientReusableComponents/ClientSideBarHeader";
import UploadDocument from "../../../assets/images/clients/Upload_document.svg";
import Add from "../../../assets/images/clients/add.svg";

const Tab = ({ each, setActiveTabID, isActiveTab = false }) => {
  const [isHover, setHover] = useState(false);
  return (
    <Button
      onClick={() => setActiveTabID(each.id)}
      key={each.id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant="outlinedSecondary"
      style={{
        border: isActiveTab
          ? "2px solid #279989"
          : isHover
          ? "2px solid #279989"
          : "2px solid rgba(39, 153, 137, 0.30)",
        color: isActiveTab ? "#279989" : isHover ? "#279989" : "#707171",
        background: each.id === 0 ? "#f0fcfa" : "white",
        borderRadius: each.id === 0 ? "8px" : "5px",
        width: "100%",
        margin: "5px 0px",
        marginBottom: each.id === 0 ? "20px" : "",
        display: "flex",
        justifyContent: "start",
        "&:hover": {
          background: "white",
          color: "#279989",
        },
      }}
    >
      {each.id === 0 ? (
        "+"
      ) : (
        <svg
          width="7"
          height="14"
          viewBox="0 0 7 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1.5L6 7L1 12.5"
            stroke={isHover ? "#279989" : "#969999"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
      <span style={{ margin: "auto" }}> {each.occupation_type}</span>
    </Button>
  );
};

export const CarePlanSideBar = ({
  clientId,
  clientMainData,
  setTabId,
  activeTabID,
}) => {
  const [userName, setUserName] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [mcnTabs, setMcnTabs] = useState([]);
  const setActiveTabID = (tab) => {
    setActiveTab(tab);
    setTabId(tab);
  };

  useEffect(() => {
    setActiveTab(activeTabID);
  }, [activeTabID]);

  useEffect(() => {
    if (clientMainData) {
      setUserName(
        `${clientMainData?.clientInfo?.first_name || ""} 
        ${clientMainData?.clientInfo?.middle_name || ""}
        ${clientMainData?.clientInfo?.last_name || ""}`
      );
    }
  }, [clientMainData]);

  useEffect(() => {
    const fetchNetwork = async () => {
      if (!clientId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/care-network-get-all/${clientId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          const info = data.data;
          setMcnTabs(info);
          if (activeTab === "") {
            setActiveTab(info[0].id);
            setTabId(info[0].id);
          } else {
            const active = info.filter((f) => f.id === activeTab)[0];
            setActiveTab(active.id);
            setTabId(active.id);
          }
        } else {
          setMcnTabs([]);
          setActiveTab(0);
          setTabId(0);
        }
      } catch (e) {
        console.error("error", e.message);
      }
    };
    fetchNetwork();
  }, [clientId, setTabId, activeTab]);

  const [assessment, setAssessment] = useState("");
  const [inputassessment, setInputAssessment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [originalAssessments, setOriginalAssessments] = useState([]);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const token = getCookie();
        const BASE_URL = "https://3.8.158.142:2443/";
        const URL = `${BASE_URL}api/careplan-assessment/102`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          const modifiedAssessmentList = data.data.map((each) => ({
            ...each,
            id: each.id,
            text: each.assessment_name,
            value: each.assessment_name,
          }));
          setOriginalAssessments(modifiedAssessmentList);
        } else {
          setOriginalAssessments([]);
          setAssessment("");
          setInputAssessment("");
        }
      } catch (e) {
        console.log("error", e.message);
        setOriginalAssessments([]);
        setAssessment("");
        setInputAssessment("");
      }
    };

    fetchAssessments();
    setErrorMessage("");
  }, [clientId]);

  const handleChange = (e, newassessment) => {
    setAssessment(newassessment);
    setErrorMessage(""); // Clear error when a assessment is selected
    //onAssessmentSelect(newassessment); // Pass the selected assessment to the parent
  };

  return (
    <Box
      sx={{
        background: "#F0FCFA",
        borderRadius: "8px",
        padding: "10px",
        height: "100%",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column", // Ensure children stack vertically
      }}
    >
      <ClientSideBarHeader
        clientMainData={clientMainData}
        //isEditMode={isEditMode}
      />
      <Box
        sx={{
          width: { xs: "100%" },
          gap: 1,
          overflowY: "auto",
          maxHeight: "300px", // Set a maximum height to trigger scrolling
          padding: "10px 10px",
          //boxSizing: "border-box", // Include padding and border in height calculation
        }}
      >
        <Grid container fullWidth>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "left" }}>
              <FormLabel>
                <Typography variant="h6">Assessment</Typography>
              </FormLabel>
              <Autocomplete
                value={assessment}
                onChange={handleChange}
                inputValue={inputassessment}
                onInputChange={(e, newInputassessment) =>
                  setInputAssessment(newInputassessment)
                }
                options={originalAssessments}
                getOptionLabel={(option) => option?.text || ""}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "Center",
                        width: "100%",
                      }}
                    >
                      <img
                        src={UploadDocument}
                        alt="Form Icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                        }} // Adjust size as needed
                      />
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {option.text}
                      </Typography>
                      <img
                        src={Add}
                        alt="Add Icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                        }} // Adjust size as needed
                      />
                    </Box>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Assessment"
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "0px 0px ! important",
                        paddingLeft: "0px ! important", // Space for the icon
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <img
                          src={UploadDocument}
                          alt="Form Icon"
                          style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }} // Adjust size as needed
                        />
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "left" }}>
              <FormLabel>
                <Typography variant="h6">Risk Assessment</Typography>
              </FormLabel>
              <Autocomplete
                value={assessment}
                onChange={handleChange}
                inputValue={inputassessment}
                onInputChange={(e, newInputassessment) =>
                  setInputAssessment(newInputassessment)
                }
                options={originalAssessments}
                getOptionLabel={(option) => option?.text || ""}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "Center",
                        width: "100%",
                      }}
                    >
                      <img
                        src={UploadDocument}
                        alt="Form Icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                        }} // Adjust size as needed
                      />
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {option.text}
                      </Typography>
                      <img
                        src={Add}
                        alt="Add Icon"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                        }} // Adjust size as needed
                      />
                    </Box>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Risk Assessment"
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "0px 0px ! important",
                        paddingLeft: "0px ! important", // Space for the icon
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <img
                          src={UploadDocument}
                          alt="Form Icon"
                          style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }} // Adjust size as needed
                        />
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ margin: "20px 0px" }}>
            <Button
              variant="outlined"
              color="secondary"
              //onClick={toggleEdit}
              sx={{ margin: 0, height: "33px", width: "100%" }}
            >
              Upload Document
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              //onClick={toggleEdit}
              sx={{ margin: 0, height: "33px", width: "100%" }}
            >
              Download Care Plan
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
