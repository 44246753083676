import {
  Grid,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomizedSwitches from "./CustomizedSwitches";

import Admin from "../../../assets/images/users/Admin.svg";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import {
  Prefix,
  Name,
  Email,
  Calendar,
  RadioInput,
  NumberInput,
  Country,
  Dropdown,
  Religion,
  Currency,
  EthnicGroup,
} from "../../reusableComponents"; // Adjust the path
import ClientInfoCheckBoxList from "./ClientInfoCheckBoxList";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Contact from "../../reusableComponents/Contact";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";
import { specialists } from "../../../cms/dropDown";
import { maritalStatus } from "../../../cms/dropDown";
import { invoiceFrequency } from "../../../cms/dropDown";

import ToggleButton from "../../common/toggleButton/ToggleButton";

import { ClientHeader } from "../../clients/clientReusableComponents/components";

const aboutMeInfo = {
  clientInfo: {
    prefix: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    contact_number: "",
    telephone_codes: "",
    email: "",
    address: "",
    city: "",
    postcode: "",
    country: "",
  },
  personalInfo: {
    client_id: "",
    preferred_name: "",
    preferred_contact_method: [],
    dob: "",
    age: "",
    gender: "",
    religion: "",
    marital_status: "",
    ethnicity: "",
  },
  nextToKinInfo: {
    prefix: "",
    name: "",
    relationship_service_user: "",
    address: "",
    city: "",
    postcode: "",
    country: "",
    telephone_codes: "",
    contact_number: "",
    email: "",
    preferred_contact_method: [],
  },
  fundingYes: {
    privately_funded: "",
    take_financial_decision: "",
    name: "",
    dob: "",
    telephone_codes: "",
    contact_number: "",
    relationship_client: "",
    email: "",
    address: "",
    city: "",
    postcode: "",
    country: "",
    nhs_number: "",
    hourly_rate: "",
    invoice_frequency: "",
  },
  fundingNo: {
    funding_organization_name: "",
    patient_access: "",
    purchase_order: "",
    service_agreement: "",
    nhs_number: "",
    hourly_rate: "",
  },
};

// Get Current Date and subtract by 16 Years to restrict Date of Birth to be 16 years and above. Use this in max
const today = new Date();
const currentDate = dayjs().subtract(16, "year");
const presentDate = dayjs().subtract(0, "year");

export const ClientAboutMeEdit = ({
  toggleEditMode,
  clientMainData,
  tabsListConstants,
  setHasUnsavedChanges,
}) => {
  const [form, setForm] = useState(aboutMeInfo);
  const navigate = useNavigate();

  const clientInfo_prefixRef = useRef();
  const clientInfo_first_nameRef = useRef();
  const clientInfo_middle_nameRef = useRef();
  const clientInfo_last_nameRef = useRef();
  const clientInfo_contact_numberRef = useRef();
  // const clientInfo_telephone_codesRef = useRef();
  const clientInfo_emailRef = useRef();
  const clientInfo_addressRef = useRef();
  const clientInfo_cityRef = useRef();
  const clientInfo_postcodeRef = useRef();
  const clientInfo_countryRef = useRef();

  const personalInfo_preferred_nameRef = useRef();
  const personalInfo_preferred_contact_methodRef = useRef();
  const personalInfo_dobRef = useRef();
  const personalInfo_ageRef = useRef();
  const personalInfo_genderRef = useRef();
  const personalInfo_religionRef = useRef();
  const personalInfo_marital_statusRef = useRef();
  const personalInfo_ethnicityRef = useRef();

  const nextToKinInfo_prefixRef = useRef();
  const nextToKinInfo_nameRef = useRef();
  const nextToKinInfo_relationship_service_userRef = useRef();
  const nextToKinInfo_addressRef = useRef();
  const nextToKinInfo_cityRef = useRef();
  const nextToKinInfo_postcodeRef = useRef();
  const nextToKinInfo_countryRef = useRef();
  // const nextToKinInfo_telephone_codesRef = useRef();
  const nextToKinInfo_contact_numberRef = useRef();
  const nextToKinInfo_emailRef = useRef();
  const nextToKinInfo_preferred_contact_methodRef = useRef();

  const funding_privately_fundedRef = useRef();
  const funding_take_financial_decision_fundedRef = useRef();

  const fundingYes_nameRef = useRef();
  const fundingYes_dobRef = useRef();
  // const fundingYes_telephone_codesRef = useRef();
  const fundingYes_contact_numberRef = useRef();
  const fundingYes_relationship_clientRef = useRef();
  const fundingYes_emailRef = useRef();
  const fundingYes_addressRef = useRef();
  const fundingYes_cityRef = useRef();
  const fundingYes_postcodeRef = useRef();
  const fundingYes_countryRef = useRef();
  const fundingYes_nhs_numberRef = useRef();
  const fundingYes_hourly_rateRef = useRef();
  const fundingYes_invoice_frequencyRef = useRef();

  const fundingNo_funding_organization_nameRef = useRef();
  const fundingNo_patient_accessRef = useRef();
  const fundingNo_purchase_orderRef = useRef();
  const fundingNo_service_agreementRef = useRef();
  const fundingNo_nhs_numberRef = useRef();
  const fundingNo_hourly_rateRef = useRef();

  const topRef = useRef(null); // Create a ref for the top element

  const [openDialog, setOpenDialog] = useState(false);

  const [emailExistsError, setEmailExistError] = useState("");
  const [contactExistsError, setContactExistsError] = useState("");

  const [isFinacialDecisionChanged, setIsFinacialDecsionChanged] =
    useState(false);
  const [oldEmail, setOldEmail] = useState("");
  const [isOldEmailOverrides, setIsOldEmailOverrides] = useState(false);

  const [oldNumberObj, setOldNumberObj] = useState({
    oldNumber: "",
    isOldNumberOverrides: false,
  });

  const [startingForm, setStartingForm] = useState(null);

  const { userID } = useParams();

  const [userName, setUserName] = useState("");

  useEffect(() => {
    // Scroll to the top of the element referenced by topRef
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [clientMainData]); // Trigger on data load

  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  // console.log(form);

  useEffect(() => {
    const fetchUsersAPI = async () => {
      const token = getCookie();

      const BASE_URL = "https://3.8.158.142:2443/";
      const URL = `${BASE_URL}api/client/aboutMe/${userID}`;
      // const URL = `${BASE_URL}api/client/aboutMe/8`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();
      console.log("get call response..........", data.data);

      let personalContactPref = [];
      if (
        typeof data.data.personalInfo?.preferred_contact_method === "string"
      ) {
        personalContactPref =
          data.data.personalInfo?.preferred_contact_method.split(",");
      } else if (
        Array.isArray(data.data.personalInfo?.preferred_contact_method)
      ) {
        personalContactPref = data.data.personalInfo?.preferred_contact_method;
      } else {
        personalContactPref = [];
      }

      let nextKinContactPref = [];
      if (
        typeof data.data.nextToKinInfo?.preferred_contact_method === "string"
      ) {
        nextKinContactPref =
          data.data.nextToKinInfo?.preferred_contact_method.split(",");
      } else if (
        Array.isArray(data.data.nextToKinInfo?.preferred_contact_method)
      ) {
        nextKinContactPref = data.data.nextToKinInfo?.preferred_contact_method;
      } else {
        nextKinContactPref = [];
      }

      if (response.ok) {
        const convertedData = {
          ...form,
          ...data.data,
          fundingYes: {
            ...data.data.fundingYes,

            privately_funded:
              data.data?.fundingYes?.privately_funded === 1
                ? "Yes"
                : data.data?.fundingYes?.privately_funded === 0
                ? "No"
                : "",

            take_financial_decision:
              data.data?.fundingYes?.take_financial_decision === 1
                ? "Yes"
                : data.data?.fundingYes?.take_financial_decision === 0
                ? "No"
                : "",
          },
          nextToKinInfo: {
            ...data.data.nextToKinInfo,
            preferred_contact_method: nextKinContactPref,
          },
          personalInfo: {
            ...data.data.personalInfo,
            preferred_contact_method: personalContactPref,
            religion:
              data.data.personalInfo.religion === ""
                ? 0
                : data.data.personalInfo.religion,
            ethnicity:
              data.data.personalInfo.ethnicity === ""
                ? 0
                : data.data.personalInfo.ethnicity,
          },
        };

        setForm(convertedData);
        setStartingForm(convertedData);

        setIsFinacialDecsionChanged(
          data.data?.fundingYes?.privately_funded === 1
            ? true
            : data.data?.fundingYes?.privately_funded === 0
            ? true
            : false
        );
        setHasUnsavedChanges(false); // No unsaved changes on initial load
      } else {
        console.log("get personal View failed");
      }
    };
    fetchUsersAPI();
  }, [userID, setHasUnsavedChanges]);

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const showToastMessage = () => {
    toast.success("Add personal successfully", {
      position: "top-right",
      autoClose: 2000, // Duration in milliseconds
    });
  };
  const showToastFailureMessage = () => {
    toast.error("Failed to Personal user", {
      position: "top-right",
    });
  };

  // const handlekDateChange = (dateValue) =>
  //   setForm((prevState) => ({
  //     ...prevState,
  //     personalInfo: {
  //       ...prevState.personalInfo,
  //       dob: dateValue,
  //       age: calculateAge(dateValue),
  //     },
  //   }));

  function calculateAge(dateString) {
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onClickYesDialogBtn = () => {
    toggleEditMode();
    setHasUnsavedChanges(false);
  };

  const postUserAPI = async () => {
    try {
      const token = getCookie();

      const body = {
        ...form,
        fundingYes: {
          ...form.fundingYes,

          name:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.name
              : "",

          dob:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.dob
              : "",
          telephone_codes:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.telephone_codes
              : 0,
          contact_number:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.contact_number
              : "",
          email:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.email
              : "",
          address:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.address
              : "",
          city:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.city
              : "",

          postcode:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.postcode
              : "",
          country:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.country
              : 0,
          nhs_number:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.nhs_number
              : "",
          hourly_rate:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.hourly_rate
              : "",
          invoice_frequency:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.invoice_frequency
              : "",
          relationship_client:
            form.fundingYes.privately_funded === "Yes"
              ? form.fundingYes.relationship_client
              : "",

          privately_funded:
            form?.fundingYes?.privately_funded === "Yes" ? 1 : 0,

          // take_financial_decision:
          //   form?.fundingYes?.take_financial_decision === "Yes" ? 1 : 0,

          take_financial_decision:
            form?.fundingYes?.privately_funded === "No"
              ? ""
              : form.fundingYes.take_financial_decision === "Yes"
              ? 1
              : 0,
        },
        fundingNo: {
          ...form.fundingNo,

          funding_organization_name:
            form?.fundingYes?.privately_funded === "No"
              ? form.fundingNo.funding_organization_name
              : "",
          patient_access:
            form?.fundingYes?.privately_funded === "No"
              ? form.fundingNo.patient_access
              : "",
          purchase_order:
            form?.fundingYes?.privately_funded === "No"
              ? form.fundingNo.purchase_order
              : "",
          service_agreement:
            form?.fundingYes?.privately_funded === "No"
              ? form.fundingNo.service_agreement
              : "",
          nhs_number:
            form?.fundingYes?.privately_funded === "No"
              ? form.fundingNo.nhs_number
              : "",
          hourly_rate:
            form?.fundingYes?.privately_funded === "No"
              ? form.fundingNo.hourly_rate
              : "",
        },
      };

      console.log("body .form", body);

      const BASE_URL = "https://3.8.158.142:2443/";
      const URL = `${BASE_URL}api/client/aboutMe/${userID}`;
      // const URL = `${BASE_URL}api/client/aboutMe/31`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();
      console.log("Form Data Body option.body.......: ", data);

      if (response.ok && data.statusCode === 200) {
        showToastMessage();
        // setTimeout(() => {
        //   // navigate("/clients");
        // }, 3000); // Delay in milliseconds
        toggleEditMode();
      } else {
        // email already exists

        if (data.message === "Email already exists.") {
          setEmailExistError(data.message);
        }

        // contact already exists
        if (data.message === "Contact number already exists.") {
          // Contact number already exists.
          setContactExistsError(data.message);
        }

        showToastFailureMessage();
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // showToastFailureMessage();
    }
  };

  const checkEmailOverrides = () => {
    if (form.admin && oldEmail && oldEmail !== form.email) {
      // form.admin  && oldEmail && oldEmail !== form.email
      setIsOldEmailOverrides(true);
      return false;
    } else {
      // setOldEmail("")
      setIsOldEmailOverrides(false);
      return true;
    }
  };

  const checkNumberOverrides = () => {
    if (
      oldNumberObj.oldNumber &&
      oldNumberObj.oldNumber !== form.contact_number
    ) {
      setOldNumberObj({
        ...oldNumberObj,
        isOldNumberOverrides: true,
      });
      return false;
    } else {
      setOldNumberObj({
        ...oldNumberObj,
        isOldNumberOverrides: false,
      });
      return true;
    }
  };

  const checkIsValidsGiven = () => {
    // Call the handleValidations methods from the child component
    // clientInfo_prefixRef validation
    if (clientInfo_prefixRef?.current) {
      clientInfo_prefixRef?.current?.handleValidation();
    }

    // clientInfo_first_nameRef name validation
    if (clientInfo_first_nameRef?.current) {
      clientInfo_first_nameRef?.current?.handleValidation();
    }

    // last name validation
    if (clientInfo_last_nameRef?.current) {
      clientInfo_last_nameRef?.current?.handleValidation();
    }
    // clientInfo_contact_numberRef name validation
    if (clientInfo_contact_numberRef?.current) {
      clientInfo_contact_numberRef?.current?.handleValidation();
    }

    // clientInfo_emailRef validation
    if (clientInfo_emailRef?.current) {
      clientInfo_emailRef?.current?.handleValidation();
    }

    // clientInfo_addressRef validation
    if (clientInfo_addressRef?.current) {
      clientInfo_addressRef?.current?.handleValidation();
    }

    // clientInfo_cityRef validation
    if (clientInfo_cityRef?.current) {
      clientInfo_cityRef?.current?.handleValidation();
    }

    // clientInfo_postcodeRef validation
    if (clientInfo_postcodeRef?.current) {
      clientInfo_postcodeRef?.current?.handleValidation();
    }

    // clientInfo_countryRef validation
    if (clientInfo_countryRef?.current) {
      clientInfo_countryRef?.current?.handleValidation();
    }

    // personalInfo_preferred_nameRef validation
    if (personalInfo_preferred_nameRef?.current) {
      personalInfo_preferred_nameRef?.current?.handleValidation();
    }
    // personalInfo_preferred_contact_methodRef validation
    if (personalInfo_preferred_contact_methodRef?.current) {
      personalInfo_preferred_contact_methodRef?.current?.handleValidation();
    }
    // personalInfo_dobRef validation
    if (personalInfo_dobRef?.current) {
      personalInfo_dobRef?.current?.handleValidation();
    }

    // personalInfo_ageRef validation
    if (personalInfo_ageRef?.current) {
      personalInfo_ageRef?.current?.handleValidation();
    }
    // personalInfo_genderRef validation
    if (personalInfo_genderRef?.current) {
      personalInfo_genderRef?.current?.handleValidation();
    }

    // personalInfo_religionRef validation
    if (personalInfo_religionRef?.current) {
      personalInfo_religionRef?.current?.handleValidation();
    }

    // personalInfo_marital_statusRef validation
    if (personalInfo_marital_statusRef?.current) {
      personalInfo_marital_statusRef?.current?.handleValidation();
    }
    // personalInfo_ethnicityRef validation
    if (personalInfo_ethnicityRef?.current) {
      personalInfo_ethnicityRef?.current?.handleValidation();
    }

    // next to kin Info validations..........?

    // nextToKinInfo_prefixRef validation
    if (nextToKinInfo_prefixRef?.current) {
      nextToKinInfo_prefixRef?.current?.handleValidation();
    }

    // nextToKinInfo_nameRef validation
    if (nextToKinInfo_nameRef?.current) {
      nextToKinInfo_nameRef?.current?.handleValidation();
    }
    // nextToKinInfo_relationship_service_userRef validation
    if (nextToKinInfo_relationship_service_userRef?.current) {
      nextToKinInfo_relationship_service_userRef?.current?.handleValidation();
    }
    // nextToKinInfo_addressRef validation
    if (nextToKinInfo_addressRef?.current) {
      nextToKinInfo_addressRef?.current?.handleValidation();
    }
    // nextToKinInfo_cityRef validation
    if (nextToKinInfo_cityRef?.current) {
      nextToKinInfo_cityRef?.current?.handleValidation();
    }
    // nextToKinInfo_postcodeRef validation
    if (nextToKinInfo_postcodeRef?.current) {
      nextToKinInfo_postcodeRef?.current?.handleValidation();
    }
    // nextToKinInfo_countryRef validation
    if (nextToKinInfo_countryRef?.current) {
      nextToKinInfo_countryRef?.current?.handleValidation();
    }
    // nextToKinInfo_contact_numberRef validation
    if (nextToKinInfo_contact_numberRef?.current) {
      nextToKinInfo_contact_numberRef?.current?.handleValidation();
    }
    // nextToKinInfo_emailRef validation
    if (nextToKinInfo_emailRef?.current) {
      nextToKinInfo_emailRef?.current?.handleValidation();
    }
    // nextToKinInfo_preferred_contact_methodRef validation
    if (nextToKinInfo_preferred_contact_methodRef?.current) {
      nextToKinInfo_preferred_contact_methodRef?.current?.handleValidation();
    }

    // Funding status Info validations..........?

    // funding_privately_fundedRef validation
    if (funding_privately_fundedRef?.current) {
      funding_privately_fundedRef?.current?.handleValidation();
    }

    if (form.fundingYes.privately_funded === "Yes") {
      console.log("Hi true: ", form.fundingYes.privately_funded);
      // funding_take_financial_decision_fundedRef validation
      if (funding_take_financial_decision_fundedRef?.current) {
        funding_take_financial_decision_fundedRef?.current?.handleValidation();
      }

      // fundingYes_nameRef validation
      if (fundingYes_nameRef?.current) {
        fundingYes_nameRef?.current?.handleValidation();
      }
      // fundingYes_dobRef validation
      if (fundingYes_dobRef?.current) {
        fundingYes_dobRef?.current?.handleValidation();
      }
      // fundingYes_contact_numberRef validation
      if (fundingYes_contact_numberRef?.current) {
        fundingYes_contact_numberRef?.current?.handleValidation();
      }
      // fundingYes_relationship_clientRef validation
      if (fundingYes_relationship_clientRef?.current) {
        fundingYes_relationship_clientRef?.current?.handleValidation();
      }

      // fundingYes_emailRef validation
      if (fundingYes_emailRef?.current) {
        fundingYes_emailRef?.current?.handleValidation();
      }

      // fundingYes_addressRef validation
      if (fundingYes_addressRef?.current) {
        fundingYes_addressRef?.current?.handleValidation();
      }

      // fundingYes_cityRef validation
      if (fundingYes_cityRef?.current) {
        fundingYes_cityRef?.current?.handleValidation();
      }

      // fundingYes_postcodeRef validation
      if (fundingYes_postcodeRef?.current) {
        fundingYes_postcodeRef?.current?.handleValidation();
      }

      // fundingYes_countryRef validation
      if (fundingYes_countryRef?.current) {
        fundingYes_countryRef?.current?.handleValidation();
      }
      // fundingYes_nhs_numberRef validation
      if (fundingYes_nhs_numberRef?.current) {
        fundingYes_nhs_numberRef?.current?.handleValidation();
      }

      // fundingYes_hourly_rateRef validation
      if (fundingYes_hourly_rateRef?.current) {
        fundingYes_hourly_rateRef?.current?.handleValidation();
      }

      // fundingYes_invoice_frequencyRef validation
      if (fundingYes_invoice_frequencyRef?.current) {
        fundingYes_invoice_frequencyRef?.current?.handleValidation();
      }
    }

    if (form.fundingYes.privately_funded === "No") {
      console.log("Hi false :", form.fundingYes.privately_funded);
      //Funding status no validation..............?
      // fundingNo_funding_organization_nameRef validation
      if (fundingNo_funding_organization_nameRef?.current) {
        fundingNo_funding_organization_nameRef?.current?.handleValidation();
      }

      // fundingNo_patient_accessRef validation
      if (fundingNo_patient_accessRef?.current) {
        fundingNo_patient_accessRef?.current?.handleValidation();
      }

      // fundingNo_purchase_orderRef validation
      if (fundingNo_purchase_orderRef?.current) {
        fundingNo_purchase_orderRef?.current?.handleValidation();
      }

      // fundingNo_service_agreementRef validation
      if (fundingNo_service_agreementRef?.current) {
        fundingNo_service_agreementRef?.current?.handleValidation();
      }

      // fundingNo_nhs_numberRef validation
      if (fundingNo_nhs_numberRef?.current) {
        fundingNo_nhs_numberRef?.current?.handleValidation();
      }

      // fundingNo_hourly_rateRef validation
      if (fundingNo_hourly_rateRef?.current) {
        fundingNo_hourly_rateRef?.current?.handleValidation();
      }
    }

    return (
      clientInfo_prefixRef?.current?.handleValidation() &&
      clientInfo_first_nameRef?.current?.handleValidation() &&
      clientInfo_last_nameRef?.current?.handleValidation() &&
      clientInfo_contact_numberRef?.current?.handleValidation() &&
      clientInfo_emailRef?.current?.handleValidation() &&
      clientInfo_addressRef?.current?.handleValidation() &&
      clientInfo_cityRef?.current?.handleValidation() &&
      clientInfo_postcodeRef?.current?.handleValidation() &&
      clientInfo_countryRef?.current?.handleValidation() &&
      personalInfo_preferred_nameRef?.current?.handleValidation() &&
      personalInfo_preferred_contact_methodRef?.current?.handleValidation() &&
      personalInfo_dobRef?.current?.handleValidation() &&
      personalInfo_ageRef?.current?.handleValidation() &&
      personalInfo_genderRef?.current?.handleValidation() &&
      personalInfo_religionRef?.current?.handleValidation() &&
      personalInfo_marital_statusRef?.current?.handleValidation() &&
      personalInfo_ethnicityRef?.current?.handleValidation() &&
      nextToKinInfo_prefixRef?.current?.handleValidation() &&
      nextToKinInfo_nameRef?.current?.handleValidation() &&
      nextToKinInfo_relationship_service_userRef?.current?.handleValidation() &&
      nextToKinInfo_addressRef?.current?.handleValidation() &&
      nextToKinInfo_cityRef?.current?.handleValidation() &&
      nextToKinInfo_postcodeRef?.current?.handleValidation() &&
      nextToKinInfo_countryRef?.current?.handleValidation() &&
      nextToKinInfo_contact_numberRef?.current?.handleValidation() &&
      nextToKinInfo_emailRef?.current?.handleValidation() &&
      nextToKinInfo_preferred_contact_methodRef?.current?.handleValidation() &&
      funding_privately_fundedRef?.current?.handleValidation() &&
      ((form.fundingYes.privately_funded === "Yes" ? true : false)
        ? funding_take_financial_decision_fundedRef?.current?.handleValidation() &&
          fundingYes_nameRef?.current?.handleValidation() &&
          fundingYes_dobRef?.current?.handleValidation() &&
          fundingYes_contact_numberRef?.current?.handleValidation() &&
          fundingYes_relationship_clientRef?.current?.handleValidation() &&
          fundingYes_emailRef?.current?.handleValidation() &&
          fundingYes_addressRef?.current?.handleValidation() &&
          fundingYes_cityRef?.current?.handleValidation() &&
          fundingYes_postcodeRef?.current?.handleValidation() &&
          fundingYes_countryRef?.current?.handleValidation() &&
          fundingYes_nhs_numberRef?.current?.handleValidation() &&
          fundingYes_hourly_rateRef?.current?.handleValidation() &&
          fundingYes_invoice_frequencyRef?.current?.handleValidation()
        : fundingNo_funding_organization_nameRef?.current?.handleValidation() &&
          fundingNo_patient_accessRef?.current?.handleValidation() &&
          fundingNo_purchase_orderRef?.current?.handleValidation() &&
          fundingNo_service_agreementRef?.current?.handleValidation() &&
          fundingNo_nhs_numberRef?.current?.handleValidation() &&
          fundingNo_hourly_rateRef?.current?.handleValidation())
    );
  };

  const postUser = () => {
    // if form not changed will show view without posting the data again
    // if (isFormNotChanged()) {
    //   toggleEditMode();
    // } else {

    if (
      checkIsValidsGiven() === true &&
      checkNumberOverrides() === true &&
      checkEmailOverrides() === true
    ) {
      // if (isFormNotChanged()){
      //   toggleEditMode();
      // }
      setHasUnsavedChanges(false); // Reset unsaved state
      postUserAPI();
      console.log("Called API");
    } else {
      handleClose();
      console.log("handle close");
    }
  };

  const onSubmitForm = (e) => {
    console.log("Submit btn");
    e.preventDefault();
    postUser();
  };

  const elementWidth = {
    width: {
      xs: "100%",
      // lg: "45%",
      lg: "22%",
    },
    gap: 1,
  };

  const handleChange = (section, field, value) => {
    const updatedForm = {
      ...form,
      [section]: {
        ...form[section],
        [field]: value,
      },
    };
    setForm((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value,
      },
    }));

    setHasUnsavedChanges(!areObjectsEqual(updatedForm, startingForm)); // Detect changes
  };

  // const handleCountrySelect = (section, countryObj) => {
  //   handleChange(section, "country", countryObj ? countryObj.id : "");
  // };

  const handleDateChange = (dateValue) => {
    handleChange("personalInfo", "dob", dateValue);
    handleChange("personalInfo", "age", calculateAge(dateValue));
  };

  const FundingYesHandleDateChange = (dateValue) => {
    handleChange("fundingYes", "dob", dateValue);
  };

  console.log("form....", form);

  const isFormNotChanged = () => {
    // checking the form changes
    return JSON.stringify(startingForm) === JSON.stringify(form);
  };

  const renderPopups = () => {
    return (
      <>
        {openDialog && (
          <ConfirmationDialog
            openDialog={openDialog}
            TitleText="Do you want to leave without saving changes?"
            paraText="Any unsaved changes will be lost."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Confirm"
            onCancelFun={() => handleClose()}
            onConfirmFun={onClickYesDialogBtn}
            handleCloseDialog={handleClose}
          />
        )}
        {/* Confirmation Dialog */}
        {/* {openDialog && (
          <ConfirmationDialog
            openDialog={openDialog}
            TitleText="Are you sure you want to exit without saving the changes?"
            paraText="This will disable web application access to the user."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Yes"
            onCancelFun={() => {
              // If starting form and existing form equal (if no changes)
              if (isFormNotChanged()) {
                toggleEditMode();
              } else {
                // if form changes
                handleClose();
              }
            }}
            onConfirmFun={() => {
              // If form changes and proceeding YES
              if (!isFormNotChanged()) {
                toggleEditMode();
              }
            }}
            handleCloseDialog={handleClose}
          />
        )} */}
      </>
    );
  };

  const renderPersonalInfoJSX = () => {
    return (
      <Box
        id={tabsListConstants[0].id}
        ref={tabsListConstants[0].ref}
        sx={{ paddingTop: "15px" }}
      >
        <ClientHeader head={`${userName}'s Personal Info`} />
        <Grid container direction="row" alignItems="Top">
          <Grid item xs={12} lg={1.5}>
            <Prefix
              ref={clientInfo_prefixRef}
              onPrefixSelect={(value) =>
                handleChange("clientInfo", "prefix", value ? value.text : "")
              }
              preFilledvalue={form.clientInfo.prefix}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={3}>
            <Name
              ref={clientInfo_first_nameRef}
              label={"First Name"}
              name={"firstName"}
              placeholder={"First Name"}
              onValueChange={(value) =>
                handleChange("clientInfo", "first_name", value)
              }
              required={true}
              AllCapital={false}
              preFilledvalue={form.clientInfo?.first_name}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={3}>
            <Name
              label={"Middle Name"}
              name={"middleName"}
              placeholder={"Middle Name"}
              onValueChange={(value) =>
                handleChange("clientInfo", "middle_name", value)
              }
              required={false}
              AllCapital={false}
              ref={clientInfo_middle_nameRef}
              preFilledvalue={form.clientInfo?.middle_name || ""}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={3}>
            <Name
              label={"Last Name"}
              name={"lastName"}
              placeholder={"Last Name"}
              onValueChange={(value) =>
                handleChange("clientInfo", "last_name", value)
              }
              required={true}
              AllCapital={false}
              ref={clientInfo_last_nameRef}
              preFilledvalue={form.clientInfo?.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <Name
              label={"Preferred Name"}
              name={"Preferred Name"}
              placeholder={"Preferred Name"}
              onValueChange={(value) =>
                handleChange("personalInfo", "preferred_name", value)
              }
              // required={true}
              AllCapital={false}
              ref={personalInfo_preferred_nameRef}
              preFilledvalue={form.personalInfo?.preferred_name}
            />
          </Grid>
          <Grid item xs={12}>
            <Name
              label={"Address"}
              name={"address"}
              placeholder={"Address"}
              onValueChange={(value) =>
                handleChange("clientInfo", "address", value)
              }
              required={true}
              AllCapital={false}
              ref={clientInfo_addressRef}
              preFilledvalue={form.clientInfo?.address}
            />
          </Grid>
          <Grid item xs={12} lg={3.5}>
            <Name
              label={"City"}
              name={"city"}
              placeholder={"City"}
              onValueChange={(value) =>
                handleChange("clientInfo", "city", value)
              }
              required={true}
              AllCapital={false}
              ref={clientInfo_cityRef}
              preFilledvalue={form.clientInfo?.city}
            />
          </Grid>
          <Grid item lg={0.75} xs={false} />
          <Grid item xs={12} lg={3.5}>
            <Name
              label={"Postcode"}
              name={"postCode"}
              placeholder={"Postcode"}
              onValueChange={(value) =>
                handleChange("clientInfo", "postcode", value)
              }
              required={true}
              AllCapital={true}
              ref={clientInfo_postcodeRef}
              preFilledvalue={form.clientInfo?.postcode}
            />
          </Grid>
          <Grid item lg={0.75} xs={false} />
          <Grid item xs={12} lg={3.5}>
            <Country
              ref={clientInfo_countryRef}
              required={true}
              // onCountrySelect={(countryObj) =>
              //   handleCountrySelect("clientInfo", countryObj)
              // }
              onCountrySelect={(countryObj) =>
                handleChange(
                  "clientInfo",
                  "country",
                  countryObj ? countryObj.id : ""
                )
              }
              name=""
              preFilledvalue={form.clientInfo?.country_name}
            />
          </Grid>

          {/* toggle box       */}
          <Grid item xs={12}>
            <Box
              style={{
                // background: "red",
                borderRadius: "8px",
                background: "#F0FCFA",
                padding: "10px 15px",
                margin: "10px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: "#279989",
                  textAlign: "left",
                  padding: 0,
                  marginRight: "20px",
                }}
              >
                Or Enable geolocation and allow the admin to point the exact
                location of the client.
              </Typography>
              {/* <ToggleButton /> */}
              <CustomizedSwitches labelValue="" />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Contact
              onValueChange={(value) =>
                handleChange("clientInfo", "contact_number", value)
              }
              onCountrySelect={(telePhoneCodeObj) =>
                handleChange(
                  "clientInfo",
                  "telephone_codes",
                  telePhoneCodeObj ? telePhoneCodeObj.id : ""
                )
              }
              ref={clientInfo_contact_numberRef}
              preFilledvalue={form.clientInfo?.contact_number}
              preFilledcode={form.clientInfo?.country_code}
              contactExistsError={contactExistsError}
            />
          </Grid>

          <Grid item xs={12}>
            <Email
              onEmailValueChange={(value) =>
                handleChange("clientInfo", "email", value)
              }
              required={true}
              ref={clientInfo_emailRef}
              value={form.email}
              emailExistsError={emailExistsError}
              preFilledvalue={form.clientInfo?.email}
            />
          </Grid>

          {/* Preferred contact method */}
          <Grid item xs={12}>
            <ClientInfoCheckBoxList
              ref={personalInfo_preferred_contact_methodRef}
              label="Preferred Contact Method"
              labelList={["Email", "Telephone"]}
              checkedList={form.personalInfo?.preferred_contact_method}
              onCheckBoxChange={(checkedValues) => {
                handleChange(
                  "personalInfo",
                  "preferred_contact_method",
                  checkedValues
                );
              }}
              // required={true}
            />
          </Grid>

          <Grid item xs={12} lg={3.5}>
            <Calendar
              ref={personalInfo_dobRef}
              label="Date of Birth"
              onDateChange={handleDateChange}
              required={true}
              preFilledvalue={form.personalInfo.dob}
              maxDate={currentDate}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={2.5}>
            <NumberInput
              ref={personalInfo_ageRef}
              label="Age"
              placeholder="0"
              // maxLength={5}
              preFilledvalue={form.personalInfo.age}
              disabled // Disable the Age field
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={5}>
            <RadioInput
              ref={personalInfo_genderRef}
              label="Gender"
              required={true}
              preFilledvalue={form.personalInfo.gender}
              options={[
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
                { value: "Other", label: "Other" },
              ]}
              onValueChange={(genderValue) =>
                handleChange("personalInfo", "gender", genderValue)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Religion
              ref={personalInfo_religionRef}
              // required={false}
              onReligionSelect={(religionObj) =>
                handleChange(
                  "personalInfo",
                  "religion",
                  religionObj ? religionObj.id : 0
                )
              }
              name=""
              preFilledvalue={form.personalInfo.religion_name}
            />
          </Grid>
          <Grid item xs={12} lg={5.5}>
            <Dropdown
              ref={personalInfo_marital_statusRef}
              label="Marital Status"
              placeholder="Marital Status"
              // required={false}
              list={maritalStatus}
              onDropdownSelect={(maritalStatusObj) =>
                handleChange(
                  "personalInfo",
                  "marital_status",
                  maritalStatusObj ? maritalStatusObj.text : ""
                )
              }
              preFilledvalue={form.personalInfo.marital_status}
            />
          </Grid>
          <Grid item lg={1} xs={false} />
          <Grid item xs={12} lg={5.5}>
            <EthnicGroup
              ref={personalInfo_ethnicityRef}
              // required={false}
              onEthnicGroupSelect={(ethnicityObj) =>
                handleChange(
                  "personalInfo",
                  "ethnicity",
                  ethnicityObj ? ethnicityObj.id : 0
                )
              }
              name=""
              preFilledvalue={form.personalInfo.ethnicity_name}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderNextToKinJSX = () => {
    return (
      <div id={tabsListConstants[1].id} ref={tabsListConstants[1].ref}>
        <br />
        <ClientHeader head={`${userName}'s Next to Kin`} isHideButton={true} />
        <Box>
          <Grid container direction="row" alignItems="Top">
            <Grid item xs={12} lg={2.5}>
              <Prefix
                ref={nextToKinInfo_prefixRef}
                onPrefixSelect={(value) =>
                  handleChange(
                    "nextToKinInfo",
                    "prefix",
                    value ? value.text : ""
                  )
                }
                preFilledvalue={form.nextToKinInfo.prefix}
              />
            </Grid>
            <Grid item lg={0.5} xs={false} />
            <Grid item xs={12} lg={9}>
              <Name
                label={"Name"}
                name={"Name"}
                placeholder={"Name"}
                onValueChange={(value) =>
                  handleChange("nextToKinInfo", "name", value)
                }
                required={true}
                AllCapital={false}
                ref={nextToKinInfo_nameRef}
                preFilledvalue={form.nextToKinInfo.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Name
                label={"Relationship to Service User"}
                name={"Relationship"}
                placeholder={"Relationship to Service User"}
                onValueChange={(value) =>
                  handleChange(
                    "nextToKinInfo",
                    "relationship_service_user",
                    value
                  )
                }
                required={true}
                AllCapital={false}
                ref={nextToKinInfo_relationship_service_userRef}
                preFilledvalue={form.nextToKinInfo.relationship_service_user}
              />
            </Grid>
            <Grid item xs={12}>
              <Name
                label={"Address"}
                name={"address"}
                placeholder={"Address"}
                onValueChange={(value) =>
                  handleChange("nextToKinInfo", "address", value)
                }
                required={true}
                AllCapital={false}
                ref={nextToKinInfo_addressRef}
                preFilledvalue={form.nextToKinInfo.address}
              />
            </Grid>
            <Grid item xs={12} lg={3.5}>
              <Name
                label={"City"}
                name={"city"}
                placeholder={"City"}
                onValueChange={(value) =>
                  handleChange("nextToKinInfo", "city", value)
                }
                required={true}
                AllCapital={false}
                ref={nextToKinInfo_cityRef}
                preFilledvalue={form.nextToKinInfo.city}
              />
            </Grid>
            <Grid item lg={0.75} xs={false} />
            <Grid item xs={12} lg={3.5}>
              <Name
                label={"Postcode"}
                name={"postCode"}
                placeholder={"Postcode"}
                onValueChange={(value) =>
                  handleChange("nextToKinInfo", "postcode", value)
                }
                required={true}
                AllCapital={true}
                ref={nextToKinInfo_postcodeRef}
                preFilledvalue={form.nextToKinInfo.postcode}
              />
            </Grid>
            <Grid item lg={0.75} xs={false} />
            <Grid item xs={12} lg={3.5}>
              <Country
                ref={nextToKinInfo_countryRef}
                required
                name=""
                preFilledvalue={form.nextToKinInfo.country_name}
                onCountrySelect={(countryObj) =>
                  handleChange(
                    "nextToKinInfo",
                    "country",
                    countryObj ? countryObj.id : ""
                  )
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Contact
                onValueChange={(value) =>
                  handleChange("nextToKinInfo", "contact_number", value)
                }
                onCountrySelect={(telePhoneCodeObj) =>
                  handleChange(
                    "nextToKinInfo",
                    "telephone_codes",
                    telePhoneCodeObj ? telePhoneCodeObj.id : 0
                  )
                }
                ref={nextToKinInfo_contact_numberRef}
                preFilledvalue={form.nextToKinInfo.contact_number}
                preFilledcode={form.nextToKinInfo.country_code}
                contactExistsError={contactExistsError}
              />
            </Grid>
            <Grid item xs={12}>
              <Email
                onEmailValueChange={(value) =>
                  handleChange("nextToKinInfo", "email", value)
                }
                // required={false}
                ref={nextToKinInfo_emailRef}
                // value={form.email}
                preFilledvalue={form.nextToKinInfo.email}
                emailExistsError={emailExistsError}
              />
            </Grid>
            <Grid item xs={12}>
              <ClientInfoCheckBoxList
                ref={nextToKinInfo_preferred_contact_methodRef}
                label="Preferred Contact Method"
                labelList={["Email", "Telephone"]}
                checkedList={form.nextToKinInfo?.preferred_contact_method}
                onCheckBoxChange={(checkedValues) => {
                  handleChange(
                    "nextToKinInfo",
                    "preferred_contact_method",
                    checkedValues
                  );
                }}
                // required={true}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  };

  const renderFundStatus = () => {
    return (
      <div id={tabsListConstants[2].id} ref={tabsListConstants[2].ref}>
        <br />
        <ClientHeader
          head={`${userName}'s Funding Status`}
          isHideButton={true}
        />

        <div style={{ textAlign: "left" }}>
          <Typography
            variant="body1"
            sx={{
              paddingLeft: 0,
              fontStyle: "italic",
              fontFamily: "Arvo",
              fontSize: "13 px",
              fontWeight: "400",
              // lineHeight: "10px",
            }}
          >
            {`Please fill in the below funding details for ${userName}.`}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              paddingLeft: 0,
              fontStyle: "italic",
              fontFamily: "Arvo",
              fontSize: "13px",
              fontWeight: "400",
              // lineHeight: "10px",
            }}
          >
            All the sections are not mandatory. Please try to fill in as much as
            you can to generate the invoice without hassle.
          </Typography>
        </div>

        <Grid item xs={12} lg={5}>
          <RadioInput
            ref={funding_privately_fundedRef}
            label={`Is ${userName}'s privately funded?`}
            required={true}
            // preFilledvalue={form?.fundingYes?.privately_funded ? "Yes" : "No"}
            // preFilledvalue={"No"}

            preFilledvalue={form?.fundingYes?.privately_funded}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            // onValueChange={(privateValue) => {
            //   setForm({
            //     ...form,
            //     fundingYes: {
            //       ...form.fundingYes,
            //       privately_funded: privateValue,
            //     },
            //   });

            // if (privateValue === "No") {
            //   // Change here if we requires
            //   // setIsFinacialDecsionChanged(false);
            //   setForm((prv) => ({
            //     ...prv,
            //     fundingYes: {
            //       ...prv.fundingYes,
            //       privately_funded: privateValue,
            //       take_financial_decision: 0,
            //     },
            //   }));
            // }
            // }}
            onValueChange={(value) =>
              handleChange("fundingYes", "privately_funded", value)
            }
          />
        </Grid>

        {/* on private fund NO */}
        {form?.fundingYes?.privately_funded === "No" && (
          <Box
            sx={{
              background: "#F0FCFA",
              padding: "10px 15px",
              borderRadius: "8px",
            }}
          >
            <Grid container direction="row" alignItems="Top">
              <Grid item xs={12}>
                <Name
                  label={"Name of The Funding Organization"}
                  name={"FundingOrganization"}
                  placeholder={"Name of The Funding Organization"}
                  onValueChange={(value) =>
                    handleChange(
                      "fundingNo",
                      "funding_organization_name",
                      value
                    )
                  }
                  required={true}
                  AllCapital={false}
                  ref={fundingNo_funding_organization_nameRef}
                  preFilledvalue={form.fundingNo.funding_organization_name}
                />
              </Grid>
              <Grid item xs={12} lg={5.5}>
                <Name
                  label={"PA Number (Patient Access)"}
                  name={"Panumber"}
                  placeholder={"PA Number"}
                  onValueChange={(value) =>
                    handleChange("fundingNo", "patient_access", value)
                  }
                  required={true}
                  AllCapital={true}
                  maxLength={15}
                  ref={fundingNo_patient_accessRef}
                  preFilledvalue={form.fundingNo.patient_access}
                />
              </Grid>
              <Grid item lg={1} xs={false} />
              <Grid item xs={12} lg={5.5}>
                <Name
                  label={"PO Number (Purchase Order)"}
                  name={"Ponumber"}
                  placeholder={"PO Number"}
                  onValueChange={(value) =>
                    handleChange("fundingNo", "purchase_order", value)
                  }
                  required={true}
                  AllCapital={true}
                  maxLength={15}
                  ref={fundingNo_purchase_orderRef}
                  preFilledvalue={form.fundingNo.purchase_order}
                />
              </Grid>
              <Grid item xs={12} lg={5.5}>
                <Name
                  label={"SA Number (Service Agreement)"}
                  name={"Sanumber"}
                  placeholder={"SA Number"}
                  onValueChange={(value) =>
                    handleChange("fundingNo", "service_agreement", value)
                  }
                  required={true}
                  AllCapital={true}
                  maxLength={15}
                  ref={fundingNo_service_agreementRef}
                  preFilledvalue={form.fundingNo.service_agreement}
                />
              </Grid>
              <Grid item lg={1} xs={false} />
              <Grid item xs={12} lg={5.5}>
                <NumberInput
                  label={"NHS Number"}
                  name={"Nhsnumber"}
                  placeholder={"NHS Number"}
                  onValueChange={(value) =>
                    handleChange("fundingNo", "nhs_number", value)
                  }
                  required={true}
                  AllCapital={false}
                  maxLength={10}
                  ref={fundingNo_nhs_numberRef}
                  preFilledvalue={form.fundingNo.nhs_number}
                />
              </Grid>
              <Grid item xs={12}>
                <Currency
                  label={"Hourly Rate"}
                  name={"Hourlyrate"}
                  placeholder={"Hourly Rate"}
                  maxLength={8}
                  onValueChange={(value) =>
                    handleChange("fundingNo", "hourly_rate", value)
                  }
                  required={true}
                  AllCapital={false}
                  ref={fundingNo_hourly_rateRef}
                  preFilledvalue={form.fundingNo.hourly_rate}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {form?.fundingYes?.privately_funded === "Yes" && (
          <Grid item xs={12}>
            <RadioInput
              ref={funding_take_financial_decision_fundedRef}
              label={`Does ${userName} has capacity to take financial decision?`}
              required={true}
              // preFilledvalue={
              //   form?.fundingYes?.take_financial_decision ? "Yes" : "No"
              // }
              preFilledvalue={form?.fundingYes?.take_financial_decision || ""}
              options={[
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
              // onValueChange={(financialDesionValue) => {
              //   setForm({
              //     ...form,
              //     fundingYes: {
              //       ...form.fundingYes,
              //       take_financial_decision: financialDesionValue,
              //     },
              //   });
              //   setIsFinacialDecsionChanged(true);
              // }}
              onValueChange={(financialDesionValue) => {
                handleChange(
                  "fundingYes",
                  "take_financial_decision",
                  financialDesionValue
                );
                setIsFinacialDecsionChanged(true);
              }}
            />
          </Grid>
        )}

        <br />

        {(form?.fundingYes?.take_financial_decision === "Yes" ||
          form?.fundingYes?.take_financial_decision === "No") &&
          form?.fundingYes?.privately_funded === "Yes" &&
          isFinacialDecisionChanged === true && (
            <Box
              sx={{
                background: "#F0FCFA",
                padding: "10px 15px",
                borderRadius: "8px",
              }}
            >
              <Grid container direction="row" alignItems="Top">
                <Grid item xs={12} lg={5.5}>
                  <Name
                    label={"Name"}
                    name={"Name"}
                    placeholder={"Name"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "name", value)
                    }
                    required={true}
                    AllCapital={false}
                    ref={fundingYes_nameRef}
                    preFilledvalue={form.fundingYes.name}
                  />
                </Grid>
                <Grid item lg={1} xs={false} />
                <Grid item xs={12} lg={5.5}>
                  <Calendar
                    ref={fundingYes_dobRef}
                    label="Date of Birth"
                    onDateChange={FundingYesHandleDateChange}
                    required={true}
                    preFilledvalue={form.fundingYes.dob}
                    maxDate={presentDate}
                  />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                  <Contact
                    onValueChange={(value) =>
                      handleChange("fundingYes", "contact_number", value)
                    }
                    onCountrySelect={(telePhoneCodeObj) =>
                      handleChange(
                        "fundingYes",
                        "telephone_codes",
                        telePhoneCodeObj ? telePhoneCodeObj.id : 0
                      )
                    }
                    ref={fundingYes_contact_numberRef}
                    preFilledvalue={form.fundingYes?.contact_number}
                    preFilledcode={form.fundingYes?.country_code}
                    // contactExistsError={contactExistsError}
                  />
                </Grid>
                <Grid item lg={1} xs={false} />
                <Grid item xs={12} lg={5.5}>
                  <Name
                    label={"Relationship to the Client"}
                    name={"Relationship to the Client"}
                    placeholder={"Relationship to the Client"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "relationship_client", value)
                    }
                    required={true}
                    AllCapital={false}
                    ref={fundingYes_relationship_clientRef}
                    preFilledvalue={form.fundingYes.relationship_client}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Email
                    onEmailValueChange={(value) =>
                      handleChange("fundingYes", "email", value)
                    }
                    required={true}
                    ref={fundingYes_emailRef}
                    value={form.email}
                    emailExistsError={emailExistsError}
                    preFilledvalue={form.fundingYes.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Name
                    label={"Address"}
                    name={"address"}
                    placeholder={"Address"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "address", value)
                    }
                    required={true}
                    AllCapital={false}
                    ref={fundingYes_addressRef}
                    preFilledvalue={form.fundingYes.address}
                  />
                </Grid>
                <Grid item xs={12} lg={3.5}>
                  <Name
                    label={"City"}
                    name={"city"}
                    placeholder={"City"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "city", value)
                    }
                    required={true}
                    AllCapital={false}
                    ref={fundingYes_cityRef}
                    preFilledvalue={form.fundingYes.city}
                  />
                </Grid>
                <Grid item lg={0.75} xs={false} />
                <Grid item xs={12} lg={3.5}>
                  <Name
                    label={"Postcode"}
                    name={"postCode"}
                    placeholder={"Postcode"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "postcode", value)
                    }
                    required={true}
                    AllCapital={true}
                    ref={fundingYes_postcodeRef}
                    preFilledvalue={form.fundingYes.postcode}
                  />
                </Grid>
                <Grid item lg={0.75} xs={false} />
                <Grid item xs={12} lg={3.5}>
                  <Country
                    ref={fundingYes_countryRef}
                    required
                    onCountrySelect={(countryObj) =>
                      handleChange(
                        "fundingYes",
                        "country",
                        countryObj ? countryObj.id : ""
                      )
                    }
                    name=""
                    preFilledvalue={form.fundingYes.country_name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <NumberInput
                    label={"NHS Number"}
                    name={"NHS Number"}
                    placeholder={"NHS Number"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "nhs_number", value)
                    }
                    required={true}
                    AllCapital={false}
                    maxLength={10}
                    ref={fundingYes_nhs_numberRef}
                    preFilledvalue={form.fundingYes.nhs_number}
                  />
                </Grid>

                <Grid item xs={12} lg={5.5}>
                  <Currency
                    label={"Hourly Rate"}
                    name={"Hourly Rate"}
                    placeholder={"Hourly Rate"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "hourly_rate", value)
                    }
                    required={true}
                    maxLength={8}
                    AllCapital={false}
                    ref={fundingYes_hourly_rateRef}
                    preFilledvalue={form.fundingYes.hourly_rate}
                  />
                </Grid>
                <Grid item lg={1} xs={false} />
                <Grid item xs={12} lg={5.5}>
                  <Dropdown
                    ref={fundingYes_invoice_frequencyRef}
                    label="Invoice Frequency"
                    placeholder="Invoice Frequency"
                    required={true}
                    list={invoiceFrequency}
                    onDropdownSelect={(invoicefrequency) =>
                      handleChange(
                        "fundingYes",
                        "invoice_frequency",
                        invoicefrequency ? invoicefrequency.text : ""
                      )
                    }
                    preFilledvalue={form.fundingYes.invoice_frequency}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

        {/* {form?.fundingYes?.take_financial_decision === "No" &&
          isFinacialDecisionChanged === true && (
            <Box
              sx={{
                background: "#F0FCFA",
                padding: "10px 15px",
                borderRadius: "8px",
              }}
            >
             <Grid container direction="row" alignItems="Top">
                <Grid item xs={12} lg={5.5}>
                  <Name
                    label={"Name"}
                    name={"Name"}
                    placeholder={"Name"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "name", value)
                    }
                    required={true}
                    AllCapital={false}
                    ref={fundingYes_nameRef}
                    preFilledvalue={form.fundingYes.name}
                  />
                </Grid>
                <Grid item lg={1} xs={false} />
                <Grid item xs={12} lg={5.5}>
                  <Calendar
                    ref={fundingYes_dobRef}
                    label="Date of Birth"
                    onDateChange={FundingYesHandleDateChange}
                    required={true}
                    preFilledvalue={form.fundingYes.dob}
                    // maxDate={presentDate}
                  />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                  <Contact
                    onValueChange={(value) =>
                      handleChange("fundingYes", "contact_number", value)
                    }
                    onCountrySelect={(telePhoneCodeObj) =>
                      handleChange(
                        "fundingYes",
                        "telephone_codes",
                        telePhoneCodeObj ? telePhoneCodeObj.id : ""
                      )
                    }
                    ref={fundingYes_contact_numberRef}
                    preFilledvalue={form.fundingYes?.contact_number}
                    preFilledcode={form.fundingYes?.country_code}
                    // contactExistsError={contactExistsError}
                  />
                </Grid>
                <Grid item lg={1} xs={false} />
                <Grid item xs={12} lg={5.5}>
                  <Name
                    label={"Relationship to the Client"}
                    name={"Relationship to the Client"}
                    placeholder={"Relationship to the Client"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "relationship_client", value)
                    }
                    required={true}
                    AllCapital={false}
                    ref={fundingYes_relationship_clientRef}
                    preFilledvalue={form.fundingYes.relationship_client}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Email
                    onEmailValueChange={(value) =>
                      handleChange("fundingYes", "email", value)
                    }
                    required={true}
                    ref={fundingYes_emailRef}
                    value={form.email}
                    emailExistsError={emailExistsError}
                    preFilledvalue={form.fundingYes.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Name
                    label={"Address"}
                    name={"address"}
                    placeholder={"Address"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "address", value)
                    }
                    required={true}
                    AllCapital={false}
                    ref={fundingYes_addressRef}
                    preFilledvalue={form.fundingYes.address}
                  />
                </Grid>
                <Grid item xs={12} lg={3.5}>
                  <Name
                    label={"City"}
                    name={"city"}
                    placeholder={"City"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "city", value)
                    }
                    required={true}
                    AllCapital={false}
                    ref={fundingYes_cityRef}
                    preFilledvalue={form.fundingYes.city}
                  />
                </Grid>
                <Grid item lg={0.75} xs={false} />
                <Grid item xs={12} lg={3.5}>
                  <Name
                    label={"Postcode"}
                    name={"postCode"}
                    placeholder={"Postcode"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "postcode", value)
                    }
                    required={true}
                    AllCapital={true}
                    ref={fundingYes_postcodeRef}
                    preFilledvalue={form.fundingYes.postcode}
                  />
                </Grid>
                <Grid item lg={0.75} xs={false} />
                <Grid item xs={12} lg={3.5}>
                  <Country
                    ref={fundingYes_countryRef}
                    required
                    onCountrySelect={(countryObj) =>
                      handleChange(
                        "fundingYes",
                        "country",
                        countryObj ? countryObj.id : 0
                      )
                    }
                    name=""
                    preFilledvalue={form.fundingYes.country_name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <NumberInput
                    label={"NHS Number"}
                    name={"NHS Number"}
                    placeholder={"NHS Number"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "nhs_number", value)
                    }
                    required={true}
                    AllCapital={false}
                     maxLength ={10}
                    ref={fundingYes_nhs_numberRef}
                    preFilledvalue={form.fundingYes.nhs_number}
                  />
                </Grid>

                <Grid item xs={12} lg={5.5}>
                  <Currency
                    label={"Hourly Rate"}
                    name={"Hourly Rate"}
                    placeholder={"Hourly Rate"}
                    onValueChange={(value) =>
                      handleChange("fundingYes", "hourly_rate", value)
                    }
                    required={true}
                    maxLength={8}
                    AllCapital={false}
                    ref={fundingYes_hourly_rateRef}
                    preFilledvalue={form.fundingYes.hourly_rate}
                  />
                </Grid>
                <Grid item lg={1} xs={false} />
                <Grid item xs={12} lg={5.5}>
                  <Dropdown
                    ref={fundingYes_invoice_frequencyRef}
                    label="Invoice Frequency"
                    placeholder="Invoice Frequency"
                    required={true}
                    list={invoiceFrequency}
                    onDropdownSelect={(invoicefrequency) =>
                      handleChange(
                        "fundingYes",
                        "invoice_frequency",
                        invoicefrequency ? invoicefrequency.text : ""
                      )
                    }
                    preFilledvalue={form.fundingYes.invoice_frequency}
                  />
                </Grid>
              </Grid>
            </Box>
          )} */}
      </div>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        ref={topRef}
        // sx={{ elementWidth }}
        onSubmit={onSubmitForm}
        component="form"
        noValidate
        autoComplete="off"
      >
        {renderPersonalInfoJSX()}
        {renderNextToKinJSX()}
        {renderFundStatus()}
        <Grid item xs={12} lg={6} sx={{ mt: 4 }}>
          <Box sx={{ width: "fit-content" }}>
            <Button
              variant="customcancel"
              onClick={() => {
                if (isFormNotChanged()) {
                  toggleEditMode();
                  setHasUnsavedChanges(false); // Reset unsaved state
                } else {
                  handleOpen();
                }
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="customsave">
              Save
            </Button>
          </Box>
        </Grid>
      </Box>
      {renderPopups()}

      <ToastContainer />
    </Box>
  );
};
