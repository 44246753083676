import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { getCookie } from "../../utils/utils";

const EthnicGroup = forwardRef(
  ({ onEthnicGroupSelect, preFilledvalue, required = false }, ref) => {
    const [EthnicGroup, setEthnicGroup] = useState("");
    const [inputEthnicGroup, setInputEthnicGroup] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [originalEthnicGroups, setOriginalEthnicGroups] = useState([]);

    useEffect(() => {
      const fetchEthnicGroups = async () => {
        try {
          const token = getCookie();
          const BASE_URL = "https://3.8.158.142:2443/";
          const URL = `${BASE_URL}api/getAllEthnicGroups`;
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await fetch(URL, options);
          const data = await response.json();

          if (response.ok) {
            const modifiedEthnicGroupList = data.data.map((each) => ({
              ...each,
              id: each.id,
              text: each.ethnicity,
              value: each.ethnicity,
            }));
            setOriginalEthnicGroups(modifiedEthnicGroupList);

            const selectedEthnicGroup =
              modifiedEthnicGroupList.find(
                (option) => option.text === preFilledvalue
              ) ||
              // modifiedEthnicGroupList.find(
              //   (option) => option.text === "American Samoa"
              // ) ||
              null;

            setEthnicGroup(selectedEthnicGroup || "");
            setInputEthnicGroup(selectedEthnicGroup?.text || "");
          } else {
            setOriginalEthnicGroups([]);
            setEthnicGroup("");
            setInputEthnicGroup("");
          }
        } catch (e) {
          console.log("error", e.message);
          setOriginalEthnicGroups([]);
          setEthnicGroup("");
          setInputEthnicGroup("");
        }
      };

      fetchEthnicGroups();
      setErrorMessage("");
    }, [preFilledvalue]);

    const handleChange = (e, newEthnicGroup) => {
      setEthnicGroup(newEthnicGroup);
      setErrorMessage(""); // Clear error when a EthnicGroup is selected
      onEthnicGroupSelect(newEthnicGroup); // Pass the selected EthnicGroup to the parent
    };

    const handleValidation = () => {
      if (required && !EthnicGroup) {
        setErrorMessage("EthnicGroup is required.");
        return false;
      }
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            EthnicGroup
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>
        <Autocomplete
          value={EthnicGroup}
          onChange={handleChange}
          inputValue={inputEthnicGroup}
          onInputChange={(e, newInputEthnicGroup) =>
            setInputEthnicGroup(newInputEthnicGroup)
          }
          options={originalEthnicGroups}
          getOptionLabel={(option) => option?.text || ""}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select EthnicGroup" />
          )}
        />
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default EthnicGroup;
